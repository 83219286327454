import { PDataGrid } from "@components/core-components/grid/PDataGrid";
import ContentWrapper from "pages/players/details/components/ContentWrapper";
import { mockData, emailsColDef } from "./colDefs";
import Header from "./components/Header";

export default function Emails() {
  return (
    <>
      <ContentWrapper>
        <Header />
        <PDataGrid rowData={mockData} colDefs={emailsColDef} />
      </ContentWrapper>
    </>
  );
}
