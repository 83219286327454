import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/react";
import { Fragment } from "react";
import cx from "classnames";
import ProfileDetailsTab from "./components/ProfileDetailsTab";
import ChangePasswordTab from "./components/ChangePasswordTab";

export default function ProfileDetails() {
  return (
    <TabGroup>
      <TabList className="pl-[30px] bg-primary h-11 flex flex-row items-end">
        <Tab as={Fragment}>
          {({ hover, selected }) => (
            <button
              className={cx(
                "w-[117px] h-9  text-body-reg-13 no-underline font-medium",
                selected &&
                  "focus:outline-none focus:ring-0 bg-white rounded-t-[8px] text-gray-900",
                !selected && "text-white"
              )}
            >
              Profile Details
            </button>
          )}
        </Tab>
        <Tab as={Fragment}>
          {({ hover, selected }) => (
            <button
              className={cx(
                "w-[142px] h-9  text-body-reg-13 no-underline font-medium",
                selected &&
                  "focus:outline-none focus:ring-0 bg-white rounded-t-[8px] text-gray-900",
                !selected && "text-white"
              )}
            >
              Change Password
            </button>
          )}
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <ProfileDetailsTab />
        </TabPanel>
        <TabPanel>
          <ChangePasswordTab />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
}
