import React, { Fragment } from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import cx from "classnames";
import DepositForm from "./components/DepositForm";
import WithDrawal from "./components/Withdrawal";

const Payment: React.FC = () => {
  return (
    <TabGroup>
      <TabList className="pl-[30px] bg-primary h-11 flex flex-row items-end">
        <Tab as={Fragment}>
          {({ hover, selected }) => (
            <button
              className={cx(
                "w-[117px] h-9  text-body-reg-13 no-underline font-medium",
                selected &&
                  "focus:outline-none focus:ring-0 bg-white rounded-t-[8px] text-gray-900",
                !selected && "text-white"
              )}
            >
              Deposit
            </button>
          )}
        </Tab>
        <Tab as={Fragment}>
          {({ hover, selected }) => (
            <button
              type="button"
              className={cx(
                "w-[117px] h-9  text-body-reg-13 no-underline font-medium",
                selected &&
                  "focus:outline-none focus:ring-0 bg-white rounded-t-[8px] text-gray-900",
                !selected && "text-white"
              )}
            >
              Withdrawal
            </button>
          )}
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <DepositForm />
        </TabPanel>
      </TabPanels>
      <TabPanels>
        <TabPanel>
          <WithDrawal />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
};

export default Payment;
