import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Icon from "../icon";
import BSelect from "../select";
import dayjs from "dayjs";

type DateTimePickerProps = Readonly<{
  value: string;
  label: string;
  setValue: (date: string) => void;
  options: { label: string; value: string }[];
  selectValue: string;
  setSelectValue: (value: string) => void;
  withTime?: boolean;
  limitFutureDays?: boolean;
}>;

function DateTimePicker({
  value,
  setValue,
  label,
  options,
  selectValue,
  limitFutureDays = false,
  setSelectValue,
  withTime = true,
}: DateTimePickerProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      let formattedDate = "";
      if (withTime) {
        formattedDate = dayjs(date).format("YYYY-MM-DD HH:mm:ss.SSS");
        setIsCalendarOpen(false);
      } else {
        formattedDate = dayjs(date).format("YYYY-MM-DD");

        setIsCalendarOpen(false);
      }
      setValue(formattedDate);
    }
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectValue(e.target.value);
    setIsCalendarOpen(true);
  };

  const handleIconClick = () => {
    setIsCalendarOpen(true);
  };

  const handleInputFocus = () => {
    setIsCalendarOpen(true);
  };

  const handleCalendarClose = () => {
    setIsCalendarOpen(false);
  };

  return (
    <div className="flex border border-gray-400 items-center bg-gray-100 rounded-lg">
      <BSelect
        border={false}
        placeholder={label}
        options={options}
        value={selectValue}
        onChange={handleSelectChange}
        className="h-11 text-sm pr-0 m-0 w-1/2 border-0 focus:outline-none"
      />
      <div className="border-r border-gray-400 h-11"></div>
      <div className="flex items-center w-1/2 relative">
        <DatePicker
          selected={value ? new Date(value) : null}
          onChange={handleDateChange}
          showTimeSelect={withTime} // Show time picker only if withTime is true
          timeFormat="HH:mm"
          timeIntervals={15}
          popperClassName="z-50"
          dateFormat={withTime ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"}
          className="text-gray-700 bg-gray-100 text-sm border-none w-full px-1 py-2 focus:outline-none"
          placeholderText="dd/mm/yyyy"
          calendarClassName={`flex flex-row-reverse items-center justify-between ${
            withTime ? "w-[328px]" : "w-58"
          } bg-gray-100`}
          showYearDropdown={true}
          showMonthDropdown={true}
          scrollableYearDropdown
          maxDate={limitFutureDays ? new Date() : undefined}
          yearDropdownItemNumber={100}
          customInput={
            <input
              style={{
                background: "transparent",
                paddingRight: "5px",
              }}
              ref={inputRef}
              onFocus={handleInputFocus}
            />
          }
          open={isCalendarOpen}
          onClickOutside={handleCalendarClose}
        />

        <Icon
          iconName="calendar"
          onClick={handleIconClick}
          className="ml-0 bg-gray-100 w-[30px] px-0 absolute cursor-pointer right-[5px] top-[1px] h-full flex items-center justify-center"
        />
      </div>
    </div>
  );
}

export default DateTimePicker;
