import React from "react";
import FormLabel from "./FormLabel";
import BSelect from "@components/core-components/select";

const SelectField = ({
  label,
  options,
  value,
  onChange,
  placeholder,
}: {
  label: string;
  options: { value: string; label: string }[];
  value: string;
  onChange: (e: any) => void;
  placeholder: string;
}) => (
  <>
    <FormLabel label={label} />
    <div className="col-span-3 mr-2">
      <BSelect
        options={options}
        value={value}
        onChange={onChange}
        className="w-full h-[45px] "
        placeholder={placeholder}
      />
    </div>
  </>
);
export default SelectField;
