import PInput from "@components/core-components/input";
import BSelect from "@components/core-components/select";
import { useState } from "react";
import Icon from "@components/core-components/icon";
import { useBaseQuery } from "api/core/useBaseQuery";
import {
  COUNTRY_API_URLS,
  CURRENCY_API_URLS,
  MARKETİNG_CODES_API_URLS,
} from "config/apiUrls";
import Modal from "@components/core-components/modal";
import cx from "classnames";
interface QuickFiltersProp {
  closeFilters: boolean;
  handleExportCSV: () => void;
  searchText: string;
  handleSearch: (e: any) => void;
  setCloseFilters: (value: boolean) => void;
}

type Region = {
  id: number;
  name: string;
};

type MarketingCode = {
  id: number;
  code: string;
  affiliate_id: number;
  brand_id: number;
};

const mapOptions = (data: any, labelTitle: string) => {
  return data?.map((item: any) => ({
    value: item.id.toString(),
    label: item[labelTitle],
  }));
};

function QuickFilterHeader({
  setCloseFilters,
  closeFilters,
  searchText,
  handleSearch,
  handleExportCSV,
}: Readonly<QuickFiltersProp>) {
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedMarketing, setSelectedMarketing] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const { data: countries } = useBaseQuery<Region[]>({
    endpoint: COUNTRY_API_URLS.GET_COUNTRIES,
  });

  const { data: marketingCode } = useBaseQuery<MarketingCode[]>({
    endpoint: MARKETİNG_CODES_API_URLS.GET_MARKETING_CODES,
  });

  const { data: currency } = useBaseQuery<[]>({
    endpoint: CURRENCY_API_URLS.GET_CURRENCY,
  });

  const countryOptions = mapOptions(countries, "name");
  const currencyOptions = mapOptions(currency, "name");
  const marketingOptions = mapOptions(marketingCode, "code");

  const genderOptions = [
    { value: "1", label: "Female" },
    { value: "2", label: "Male" },
    { value: "3", label: "Other" },
  ];
  const accountOptions = [
    { value: "P", label: "Person" },
    { value: "K", label: "Kiosk" },
    { value: "L", label: "Kiosk_Player" },
    { value: "3", label: "3rd Party" },
  ];
  return (
    <>
      <div className="flex flex-col gap-5 mt-0 mb-4 xxl:gap-6 xxl:flex-row justify-between">
        <div className="flex gap-4 ">
          <button
            onClick={() => setCloseFilters(!closeFilters)}
            className="flex items-center justify-between h-10 w-40 bg-primary text-white rounded-lg hover:bg-primary-hover transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-400 font-semibold text-sm"
          >
            <span className="flex items-center p-[15px] space-x-1 justify-center w-full ">
              {" "}
              <Icon
                iconName="filter"
                svgProps={{
                  width: "21px",
                  height: "21px",
                }}
              />
              <span className="text-[13px]">
                {!closeFilters ? "Open Filters" : "Close Filters"}
              </span>
            </span>
            <div className="flex items-center justify-center w-8 h-10 bg-indigo-600 rounded-r-lg">
              <Icon
                svgProps={{
                  width: "13px",
                }}
                iconName="whiteArrow"
                className={`transition-transform duration-300  ${
                  closeFilters ? "rotate-180" : "rotate-0"
                }`}
              />
            </div>
          </button>
          <PInput
            placeholder="Search name or username..."
            iconName="search"
            showIcon={true}
            value={searchText}
            id="filter-text-box"
            onChange={handleSearch}
            className="h-10 w-full md:w-80 sm:w-70 lg:w-96"
          />
        </div>

        <div className="flex space-x-6 m:flex-column">
          <button
            onClick={handleExportCSV}
            className={`flex h-10 items-center justify-center bg-primary-light w-[165px] text-primary px-5 py-4 rounded-lg transition-colors focus:outline-none hover:bg-primary-light-hover focus:ring-2 focus:ring-indigo-400 space-x-2 font-semibold text-[13px]`}
          >
            <Icon
              iconName="download"
              svgProps={{
                width: 21,
                height: 21,
              }}
            />
            <span>Download CSV</span>
          </button>

          <button
            disabled={true}
            className={`flex h-10 items-center justify-center bg-primary-light w-[165px] hover:bg-primary-light-hover text-primary px-5 py-4 rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-400 space-x-2 font-semibold text-[13px] disabled:opacity-50 disabled:cursor-not-allowed`}
          >
            <Icon
              iconName="edit"
              svgProps={{
                width: 21,
                height: 21,
              }}
            />
            <span>Edit Columns</span>
          </button>

          <button
            onClick={() => setIsModalOpen(true)}
            className={`flex h-10 items-center justify-center bg-primary-light w-[165px] text-[13px] hover:bg-primary-light-hover text-primary px-5 py-4 rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-400 space-x-2 font-semibold `}
          >
            <Icon
              iconName="profile"
              svgProps={{
                width: 21,
                height: 21,
              }}
            />
            <span>Add User</span>
          </button>
        </div>
      </div>

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        content={
          <div
            className={cx(
              "px-[30px] pt-[34px] overflow-y-auto h-[75vh] flex-grow w-[500px]",
              showAdvancedFilters && "pb-[34px]",
              !showAdvancedFilters && "pb-[76px]"
            )}
          >
            <form className="flex flex-col gap-2 w-[437px]">
              <BSelect
                options={marketingOptions}
                value={selectedMarketing}
                onChange={(e: any) => setSelectedMarketing(e.target.value)}
                className="w-full h-10"
                placeholder="Marketing Code*"
              />
              <PInput placeholder="Username*" className="w-full h-10" />
              <PInput placeholder="Email*" className="w-full h-10" />
              <PInput placeholder="Mobile Number*" className="w-full h-10" />
              <PInput placeholder="First Name*" className="w-full h-10" />
              <PInput placeholder="Last Name*" className="w-full h-10" />
              <PInput placeholder="Password*" className="w-full h-10" />
              <PInput placeholder="Referral Number*" className="w-full h-10" />

              <BSelect
                options={accountOptions}
                value={selectedAccount}
                onChange={(e: any) => setSelectedAccount(e.target.value)}
                className="w-full h-10"
                placeholder="Account Type*"
              />

              {showAdvancedFilters && (
                <>
                  <BSelect
                    options={countryOptions}
                    value={selectedCountry}
                    onChange={(e: any) => setSelectedCountry(e.target.value)}
                    className="w-full h-10"
                    placeholder="Country*"
                  />
                  <BSelect
                    options={currencyOptions}
                    value={selectedCurrency}
                    onChange={(e: any) => setSelectedCurrency(e.target.value)}
                    className="w-full h-10"
                    placeholder="Currency*"
                  />
                  <BSelect
                    options={genderOptions}
                    value={selectedGender}
                    onChange={(e: any) => setSelectedGender(e.target.value)}
                    className="w-full h-10"
                    placeholder="Gender*"
                  />

                  <PInput
                    placeholder="Address Line 1*"
                    className="w-full h-10"
                  />
                  <PInput
                    placeholder="Address Line 2*"
                    className="w-full h-10"
                  />
                  <PInput placeholder="Postal Code" className="w-full h-10" />
                  <PInput
                    placeholder="Landline Number"
                    className="w-full h-11"
                  />
                </>
              )}

              <div
                className="flex items-center justify-center bg-gray-200 text-gray-700 rounded cursor-pointer text-body-reg-13 h-10"
                onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
                role="button"
              >
                {showAdvancedFilters ? (
                  <>
                    <Icon iconName="minus" className="mr-2 mt-1" />
                    <span>Hide Other Details</span>{" "}
                  </>
                ) : (
                  <>
                    <Icon iconName="plus" className="mr-2 mt-1" />
                    <span>Show Other Details</span>
                  </>
                )}
              </div>
            </form>
          </div>
        }
        footer={
          <div className="flex flex-row gap-3 items-center justify-between bg-white h-[70px] ">
            <button
              type="button"
              onClick={() => setIsModalOpen(false)}
              className="bg-gray-200 text-gray-900 px-4 py-2 rounded-lg hover:bg-gray-300 h-10 w-[139px] text-body-reg-13 font-semibold"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600 h-10 w-[139px] text-body-reg-13 font-semibold"
            >
              Save
            </button>
          </div>
        }
        title={"Add User"}
      />
    </>
  );
}

export default QuickFilterHeader;
