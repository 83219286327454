import React from "react";
import PlayerProfile from "./PlayerProfile";

function Header(player: {
  name: string;
  id: string;
  referralNumber: string;
  location: string;
  balance: string;
  bonusWallet: string;
  profitability: string;
  grossGamingRevenue: string;
  sportsBookAmount: string;
  casinoAmount: string;
  exchangeAmount: string;
}) {
  return (
    <div>
      <PlayerProfile
        playerName={player.name}
        playerId={player.id}
        referralNumber={player.referralNumber}
        location={player.location}
        balance={player.balance}
        bonusWallet={player.bonusWallet}
        profitability={player.profitability}
        grossGamingRevenue={player.grossGamingRevenue}
        sportsBookAmount={player.sportsBookAmount}
        casinoAmount={player.casinoAmount}
        exchangeAmount={player.exchangeAmount}
      />
    </div>
  );
}

// pipeline test
export default Header;
