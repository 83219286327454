import React from "react";

function QuickAccess() {
  return (
    <>
      <div className="flex bg-primary shadow w-full flex-col bg-gray-400 h-16 border-solid justify-center">
        <div className="flex mx-6 justify-between text-black items-center">
          <div className="flex justify-start items-center space-x-2">
            <h3 className="text-md text-white font-semibold">Quick Access:</h3>
            <button
              disabled
              style={{ borderRadius: 4 }}
              className="bg-orange w-32 px-6 text-sm disabled:opacity-50 disabled:cursor-not-allowed h-8 flex justify-center hover:bg-orange-light-hover items-center cursor-pointer"
            >
              Add Balance
            </button>
            <button
              disabled
              style={{ borderRadius: 4 }}
              className="bg-orange disabled:opacity-50 disabled:cursor-not-allowed w-32 px-6 text-sm h-8 flex justify-center hover:bg-orange-light-hover items-center cursor-pointer"
            >
              Deposit
            </button>
            <button
              disabled
              style={{ borderRadius: 4, borderWidth: 1 }}
              className="w-32 px-4  disabled:opacity-50 disabled:cursor-not-allowed text-sm bg-white text-gray-600 h-8 border-dashed border-gray-600 flex justify-center items-center cursor-pointer"
            >
              + Add Shortcut
            </button>
          </div>
          <button
            disabled
            className="underline text-sm disabled:opacity-50 disabled:cursor-not-allowed text-white flex items-center font-semibold"
          >
            Edit Shortcuts
          </button>
        </div>
      </div>
    </>
  );
}

export default QuickAccess;
