import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import { ColDef } from "ag-grid-community";
import { useCallback, useEffect } from "react";

export const PDataGrid = ({
  colDefs,
  onGridReady,
  rowData,

  gridRef,
  loading,
  gridOptions,
  DetailCellRenderer,
  showFooter = true,
}: {
  showFooter?: boolean;
  colDefs: ColDef[];

  loading?: boolean;
  gridOptions?: any;
  gridRef?: any;
  onGridReady?: any;
  DetailCellRenderer?: any;
  rowData: [];
}) => {
  const detailCellRenderer = useCallback((params: any) => {
    return <DetailCellRenderer params={params} />;
  }, []);
  const detailCellRendererParams = {};
  const loadingOverlayTemplate = `
  <div class="flex items-center justify-center h-full">
    <div class="loader ease-linear rounded-full border-4 border-t-4 border-transparent border-t-[#666CFF] h-12 w-12"></div>
  </div>
`;
  const defaultColDef: ColDef = {
    suppressAutoSize: true,
    suppressSizeToFit: true,
  };

  return (
    <div
      style={{ width: "100%", height: "100%" }}
      className={"ag-theme-quartz"}
    >
      <AgGridReact
        rowHeight={50}
        domLayout="autoHeight"
        rowData={rowData}
        headerHeight={50}
        ref={gridRef || null}
        masterDetail={true}
        detailCellRenderer={detailCellRenderer}
        detailCellRendererParams={detailCellRendererParams}
        pagination={showFooter}
        loading={loading}
        overlayLoadingTemplate={loadingOverlayTemplate}
        overlayNoRowsTemplate={
          '<span class="ag-overlay-no-rows-center">No data available</span>'
        }
        tooltipShowDelay={500}
        paginationPageSize={15}
        paginationPageSizeSelector={[15, 30, 45]}
        embedFullWidthRows={true}
        columnDefs={colDefs}
        detailRowHeight={441}
        copyHeadersToClipboard={true}
        onGridReady={onGridReady ? onGridReady : () => {}}
        detailRowAutoHeight={true}
        gridOptions={gridOptions}
        defaultColDef={defaultColDef}
      />
    </div>
  );
};
