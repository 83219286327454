import React, { useContext } from "react";
import { TabContext } from "./TabContext";
import Payment from "../tabs/payment";
import Info from "../tabs/info";
import Bonus from "../tabs/bonus";
import NotesComponent from "../tabs/notes";
import Settings from "../tabs/settings";

const ContentArea: React.FC = () => {
  const { activeTab } = useContext(TabContext);
  return (
    <section className="flex flex-col w-full max-md:max-w-full">
      {activeTab === "Payment" ? <Payment /> : <></>}
      {activeTab === "Info" ? <Info /> : <></>}
      {activeTab === "Bonus" ? <Bonus /> : <></>}
      {activeTab === "Notes" ? <NotesComponent /> : <></>}
      {activeTab === "Settings" ? <Settings /> : <></>}
    </section>
  );
};

export default ContentArea;
