import PInput from "@components/core-components/input";
import Modal from "@components/core-components/modal";
import BSelect from "@components/core-components/select";
import cx from "classnames";
import Accordion from "@components/core-components/accordion";
import Radio from "@components/core-components/radio/Index";
import { useState } from "react";
import BMultiSelect from "@components/core-components/multiselect";
import DatePickerField from "./DatePickerField";
import FormLabel from "./FormLabel";
import InputField from "./InputField";
import SelectField from "./SelectField";
import CheckboxField from "./CheckboxField";

function AddBonusModal({
  setIsModalOpen,
  isModalOpen,
}: {
  setIsModalOpen: (value: boolean) => void;
  isModalOpen: boolean;
}) {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState("");
  const [history, setHistory] = useState("");
  const [wager, setWager] = useState("2.00");
  const [expiry, setExpiry] = useState("2.00");
  const [amountPerTransaction, setAmountPerTransaction] = useState("45.00");
  const [percentageOfTransaction, setPercentageOfTransaction] =
    useState("2.00");
  const [maxTransactionAmount, setMaxTransactionAmount] = useState("2.00");
  const [minTransactionAmount, setMinTransactionAmount] = useState("2.00");
  const [selectedGames, setSelectedGames] = useState<string[]>([]);
  const [minTotalAmount, setMinTotalAmount] = useState("2.00");
  const [maxTotalAmount, setMaxTotalAmount] = useState("2.00");
  const [maxPerAccount, setMaxPerAccount] = useState("2.00");
  const [maxTimesAwarded, setMaxTimesAwarded] = useState("2.00");
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedProviders, setSelectedProviders] = useState<string[]>([]);
  const [selectedValue, setSelectedValue] = useState<string>("per_day");
  const [query, setQuery] = useState("");
  const [checkboxes, setCheckboxes] = useState({
    cancelOnCashout: true,
    restrictCashout: true,
    includedBrand: false,
    includedMarketingCode: false,
    useBeforeCash: false,
    depositingPlayersOnly: true,
    live: false,
    cashBetsContribute: false,
    wrDepositAmount: false,
  });
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const options = [
    { label: "Per Day", value: "per_day" },
    { label: "Per Week", value: "per_week" },
    { label: "Per Month", value: "per_month" },
    { label: "Per Year", value: "per_year" },
    { label: "In Total", value: "in_total" },
  ];
  const gameOptions = [
    { value: "game1", label: "Game 1" },
    { value: "game2", label: "Game 2" },
    { value: "game3", label: "Game 3" },
  ];

  const countryOptions = [
    { value: "country1", label: "Country 1" },
    { value: "country2", label: "Country 2" },
  ];

  const providerOptions = [
    { value: "provider1", label: "Provider 1" },
    { value: "provider2", label: "Provider 2" },
  ];

  const handleNumberChange = (
    setter: (value: string) => void,
    value: string
  ) => {
    // const formattedValue = parseFloat(value).toFixed(2);
    setter(value);
  };
  const handleRadioChange = (value: string) => {
    setSelectedValue(value);
  };

  const handleCheckboxChange = (key: keyof typeof checkboxes) => {
    setCheckboxes((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log({
      name,
      description,
      history,
      wager,
      expiry,
      amountPerTransaction,
      percentageOfTransaction,
      maxTransactionAmount,
      selectedGames,
      selectedCountries,
      selectedProviders,
      checkboxes,
      startDate,
      endDate,
    });
  };
  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      content={
        <div
          className={cx(
            "px-[30px] pt-[34px] overflow-y-auto h-[60vh] pb-[22px] flex-grow max-w-[817px] w-screen"
          )}
        >
          <form className="grid grid-cols-5 max-w-[817px] items-center justify-center w-full gap-3">
            <FormLabel label="Name*" />
            <InputField
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder="Name"
              id="name"
            />
            <FormLabel label="Description*" />
            <InputField
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              placeholder="Description"
              id="description"
            />
            <CheckboxField
              value={checkboxes.cancelOnCashout}
              onChange={() => handleCheckboxChange("cancelOnCashout")}
              label="Cancel On Cashout"
            />
            <CheckboxField
              value={checkboxes.restrictCashout}
              onChange={() => {
                handleCheckboxChange("restrictCashout");
              }}
              label="Restrict Cashout"
            />
            <CheckboxField
              label="Use Before Cash"
              value={checkboxes.useBeforeCash}
              onChange={() => {
                handleCheckboxChange("useBeforeCash");
              }}
            />
            <FormLabel label="Text to Appear Accounts History" />
            <InputField
              onChange={(e) => {
                setHistory(e.target.value);
              }}
              value={history}
              placeholder="Lorem Ipsum"
              id="history"
            />
            <SelectField
              label="Type*"
              options={[{ value: "Deposit", label: "Deposit" }]}
              value="Deposit"
              onChange={(e) => console.log(e.target.value)}
              placeholder="Marketing Code*"
            />
            <SelectField
              label="Currency"
              options={[
                { value: "USD", label: "USD" },
                { value: "EUR", label: "EUR" },
              ]}
              value="USD"
              onChange={(e) => console.log(e.target.value)}
              placeholder=""
            />
            <CheckboxField
              label="Depositing Players Only"
              value={checkboxes.depositingPlayersOnly}
              onChange={() => {
                handleCheckboxChange("depositingPlayersOnly");
              }}
            />
            <CheckboxField
              label="Live"
              value={checkboxes.live}
              onChange={() => {
                handleCheckboxChange("live");
              }}
            />
            <DatePickerField
              label="Start Date*"
              value={{ startDate: new Date(), endDate: null }}
              onChange={(date) => console.log(date)}
            />
            <DatePickerField
              label="End Date*"
              value={{ startDate: new Date(), endDate: null }}
              onChange={(date) => console.log(date)}
            />
            <FormLabel label="Wager Multiplier Requirement" />
            <InputField
              value={wager}
              onChange={(e) => {
                setWager(e.target.value);
              }}
              placeholder="2"
              id="wager"
              type="number"
            />
            <CheckboxField
              label="Cash Bets Contribute to WR"
              value={checkboxes.cashBetsContribute}
              onChange={() => {
                handleCheckboxChange("cashBetsContribute");
              }}
            />
            <div className=" col-span-2  pb-2  ">
              <FormLabel border={false} label="Unused Amount Expires After*" />
            </div>

            <div className="col-span-3 pr-3 flex items-center gap-5">
              <PInput
                placeholder="2"
                wrapperClassNames="flex-grow max-w-[193px]"
                id="expiry"
                type="number"
                value={expiry}
                onChange={(e) => {
                  setExpiry(e.target.value);
                }}
                className="w-full text-body-reg-13 h-[42px]  border-gray-300 rounded-lg p-2"
              />

              <div className="col-span-1 text-xs flex items-center  h-full  text-gray-700 ">
                (Days)
              </div>
            </div>
            {/* Accordions */}

            <div className="col-span-5 ">
              <Accordion title="Award Amounts">
                <div className="grid grid-cols-5 max-w-[817px] items-center w-full gap-4">
                  <FormLabel label="Amount per Transaction" />
                  <InputField
                    placeholder="45.00"
                    id="amount-per-trans"
                    type="number"
                    value={amountPerTransaction}
                    onChange={(e) => {
                      handleNumberChange(
                        setAmountPerTransaction,
                        e.target.value
                      );
                    }}
                  />

                  <div
                    style={{
                      alignItems: "flex-end",
                      gap: "15px",
                      justifyContent: "flex-end",
                    }}
                    className={`col-span-2 h-full pl-2  flex flex-col text-body-reg-14 text-gray-700  `}
                  >
                    <span className="pr-16"> Or</span>
                    <hr className=" border-gray-300 w-full " />
                  </div>
                  <div className="col-span-3"></div>

                  <FormLabel label="Percentage of Transaction" border={true} />
                  <div className="col-span-3 pr-3 flex items-center gap-5">
                    <PInput
                      placeholder="2"
                      id="expiry"
                      wrapperClassNames="flex-grow max-w-[193px]"
                      type="number"
                      className="w-full text-body-reg-13 h-[42px]  border-gray-300 rounded-lg p-2"
                      onChange={(e) => {
                        handleNumberChange(
                          setPercentageOfTransaction,
                          e.target.value
                        );
                      }}
                      value={percentageOfTransaction}
                    />

                    <div className=" text-xs flex items-center  h-full  text-gray-700 ">
                      % (Only applicable for Schemes of type <br /> Deposit &
                      Admin)
                    </div>
                  </div>
                  <FormLabel
                    label="Maximum Amount per Transaction"
                    border={false}
                  />
                  <div className="col-span-3 pr-3 flex items-center gap-5">
                    <PInput
                      placeholder="2"
                      wrapperClassNames="flex-grow max-w-[193px]"
                      id="expiry"
                      type="number"
                      className="w-full text-body-reg-13 h-[42px]  border-gray-300 rounded-lg p-2"
                      onChange={(e) => {
                        handleNumberChange(
                          setMaxTransactionAmount,
                          e.target.value
                        );
                      }}
                    />
                    <div className="col-span-2 text-xs flex items-center  h-full  text-gray-700 ">
                      (The maximum amount that this incentive <br />
                      will pay per transaction)
                    </div>
                  </div>
                </div>
              </Accordion>{" "}
            </div>
            <div className="col-span-5">
              <Accordion title="Deposit Criteria">
                <div className="grid grid-cols-5 max-w-[817px] items-center w-full gap-4">
                  <label className="col-span-2 text-body-reg-14 flex items-center   text-gray-700 w-full  pl-1 col-span-2 border-b border-gray-300 h-full pb-2">
                    Minimum Transaction Amount
                  </label>

                  <div className="col-span-3 pr-3 flex items-center gap-5">
                    <PInput
                      placeholder="2"
                      wrapperClassNames="flex-grow max-w-[193px]"
                      id="expiry"
                      type="number"
                      className="w-full text-body-reg-13 h-[42px]  border-gray-300 rounded-lg p-2"
                      onChange={(e) => {
                        handleNumberChange(
                          setMinTransactionAmount,
                          e.target.value
                        );
                      }}
                      value={minTransactionAmount}
                    />
                    <div className="col-span-2 text-xs flex items-center  h-full  text-gray-700 ">
                      % (Only applicable for Schemes of type <br /> Deposit &
                      Admin)
                    </div>
                  </div>

                  <label className="col-span-2 text-body-reg-14 flex items-center   text-gray-700 w-full  pl-1 col-span-2 border-b border-gray-300 h-full pb-2">
                    Maximum Transaction Amount
                  </label>
                  <div className="col-span-3 pr-3 flex items-center gap-5">
                    <PInput
                      placeholder="2"
                      wrapperClassNames="flex-grow max-w-[193px]"
                      id="expiry"
                      type="number"
                      className="w-full text-body-reg-13 h-[42px]  border-gray-300 rounded-lg p-2"
                    />
                    <div className=" text-xs flex items-center  h-full  text-gray-700 ">
                      % (Only applicable for Schemes of type <br /> Deposit &
                      Admin)
                    </div>{" "}
                  </div>

                  <label className="col-span-2 text-body-reg-14 flex items-center text-gray-700 w-full pl-1 border-b border-gray-300 h-full pb-2">
                    For Deposits From
                  </label>
                  <div className="col-span-3 pr-3 flex items-center gap-5">
                    <PInput
                      placeholder="2"
                      id="expiry"
                      wrapperClassNames="flex-grow"
                      type="number"
                      className=" text-body-reg-13 h-[42px] border-gray-300 rounded-lg p-2"
                    />
                    <div className="text-xs text-body-reg-14">or</div>
                    <PInput
                      wrapperClassNames="flex-grow"
                      placeholder="2"
                      className="flex-grow text-body-reg-13 h-[42px] border-gray-300 rounded-lg p-2"
                    />
                  </div>

                  <CheckboxField
                    border={false}
                    label="WR Includes Deposit Amount"
                    onChange={() => handleCheckboxChange("wrDepositAmount")}
                    value={checkboxes.wrDepositAmount}
                  />
                </div>
              </Accordion>{" "}
            </div>
            <div className="col-span-5 w-full ">
              <Accordion title="Limit Account Gain">
                <div className="grid grid-cols-5 max-w-[817px] items-center w-full gap-4">
                  <FormLabel label="Maximum Amount per Account" />
                  <InputField
                    value={maxPerAccount}
                    onChange={(e) => {
                      handleNumberChange(setMaxPerAccount, e.target.value);
                    }}
                    placeholder="45.00"
                    id="max-per-account"
                    type="number"
                  />
                  <div
                    style={{
                      alignItems: "flex-end",
                      gap: "15px",
                      justifyContent: "flex-end",
                    }}
                    className={`col-span-2 h-full pl-2  flex flex-col text-body-reg-14 text-gray-700  `}
                  >
                    <span className="pr-16"> Or</span>
                    <hr className=" border-gray-300 w-full " />
                  </div>
                  <div className="col-span-3"></div>
                  <FormLabel label="Maximum Times Awarded per Account" />
                  <InputField
                    value={maxTimesAwarded}
                    onChange={(e) => {
                      handleNumberChange(setMaxTimesAwarded, e.target.value);
                    }}
                    placeholder="45.00"
                    id="amount-per-trans"
                    type="number"
                  />
                  <div className="col-span-2">
                    {" "}
                    <FormLabel border={false} label="These Limits To Apply" />
                  </div>
                  <div className="flex col-span-3 flex-wrap items-start justify-start w-full gap-5">
                    {options.map((option) => (
                      <label
                        key={option.value}
                        className="flex w-28 items-center"
                      >
                        <Radio
                          checked={selectedValue === option.value}
                          onChange={() => handleRadioChange(option.value)}
                          name="limits"
                        />
                        <span className="text-gray-700 ml-4">
                          {option.label}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              </Accordion>
            </div>
            <div className="col-span-5 ">
              <Accordion title="Limit Bonus Scheme Liability">
                {" "}
                <div className="grid grid-cols-5 max-w-[817px] items-center w-full gap-4">
                  <label className="col-span-2 text-body-reg-14 flex items-center   text-gray-700 w-full  pl-1 col-span-2  h-full">
                    Maximum Total Amount{" "}
                  </label>

                  <div className="col-span-3 flex items-center gap-2">
                    <PInput
                      placeholder="2"
                      value={maxTotalAmount}
                      id="expiry"
                      wrapperClassNames="flex-grow  "
                      onChange={(e) => {
                        handleNumberChange(setMaxTotalAmount, e.target.value);
                      }}
                      type="number"
                      className="w-full text-body-reg-13 h-[42px]  border-gray-300 rounded-lg p-2"
                    />
                    <div className=" leading-2 tracking-wide flex items-center text-[12px] max-w-[251px]  text-gray-700 ">
                      (The maximum that this bonus scheme will pay out in total,
                      either an amount for a monetary scheme, or a number for
                      prize schemes/free spins)
                    </div>
                  </div>
                </div>
              </Accordion>{" "}
            </div>
            <div className="col-span-5  ">
              <Accordion title="Limit Scope">
                <div className="grid grid-cols-5 gap-4 w-full">
                  {/* Games */}
                  <FormLabel label="Games" />
                  <div className="col-span-3 mr-2">
                    <BMultiSelect
                      options={gameOptions}
                      selectedValues={selectedGames}
                      onChange={(values) => setSelectedGames(values)}
                      placeholder="Select options"
                      className="w-full"
                    />
                  </div>
                  {/* Countries */}
                  <FormLabel label="Countries" />
                  <div className="col-span-3 mr-2">
                    <BMultiSelect
                      options={countryOptions}
                      selectedValues={selectedCountries}
                      onChange={(values) => setSelectedCountries(values)}
                      placeholder="Select options"
                      className="w-full"
                    />
                    <div className="mt-2 text-gray-700"></div>
                  </div>
                  {/* Payment Providers */}
                  <FormLabel label="Payment Providers" />
                  <div className="col-span-3 mr-2">
                    <BMultiSelect
                      options={providerOptions}
                      selectedValues={selectedProviders}
                      onChange={(values) => setSelectedProviders(values)}
                      placeholder="Select options"
                      className="w-full"
                    />
                  </div>
                  <CheckboxField
                    label="Included"
                    border={false}
                    onChange={() => handleCheckboxChange("includedBrand")}
                    value={checkboxes.includedBrand}
                    description=" (Leave unchecked to exclude)"
                  />
                  <FormLabel label="Brands" />
                  <div className="col-span-3 mr-2">
                    <BMultiSelect
                      options={providerOptions}
                      selectedValues={selectedProviders}
                      onChange={(values) => setSelectedProviders(values)}
                      placeholder="Select options"
                      className="w-full"
                    />
                  </div>
                  <CheckboxField
                    label="Included"
                    border={false}
                    onChange={() =>
                      handleCheckboxChange("includedMarketingCode")
                    }
                    value={checkboxes.includedMarketingCode}
                    description=" (Leave unchecked to exclude)"
                  />
                  <FormLabel border={false} label="Marketing Code" />
                  <div className="col-span-3 mr-2">
                    <BSelect
                      options={providerOptions}
                      value={query}
                      onChange={(e: any) => setQuery(e.target.value)}
                      placeholder="Select Options"
                      className="w-full "
                    />
                  </div>
                </div>
              </Accordion>{" "}
            </div>
            <div className="col-span-5 ">
              <Accordion title="Limit Query">
                <div className="grid grid-cols-5 gap-4 w-full">
                  <FormLabel border={false} label="Query" />
                  <div className="col-span-3">
                    <BSelect
                      options={providerOptions}
                      value={query}
                      onChange={(e: any) => setQuery(e.target.value)}
                      placeholder="Select An Option"
                      className="w-full"
                    />
                  </div>
                </div>
              </Accordion>{" "}
            </div>
          </form>
        </div>
      }
      footer={
        <div className="flex flex-row gap-3 items-center justify-between bg-white h-[70px]">
          <button
            type="button"
            onClick={() => setIsModalOpen(false)}
            className="bg-gray-200 text-gray-900 px-4 py-2 rounded-lg hover:bg-gray-300 h-10 w-[139px] text-body-reg-13 font-semibold"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600 h-10 w-[139px] text-body-reg-13 font-semibold"
          >
            Save
          </button>
        </div>
      }
      title={"Add Bonus"}
    />
  );
}

export default AddBonusModal;
