import React from "react";
import FormLabel from "./FormLabel";
import Checkbox from "@components/core-components/checkbox";

const CheckboxField = ({
  label,
  onChange,
  value,
  border = true,
  description,
  col = 3,
}: {
  label: string;
  description?: string;
  value: boolean;
  onChange: () => void;
  col?: number;
  border?: boolean;
}) => {
  return (
    <>
      <div
        onClick={onChange}
        style={{
          gap: "15px",
          alignItems: "center",
          justifyContent: border ? "center" : "center",
        }}
        className={`col-span-2 h-full  flex flex-col  ml-2 cursor-pointer ${
          border ? "border-b border-gray-300 " : ""
        } `}
      >
        <label className="text-body-reg-14 text-gray-700 w-full">{label}</label>
      </div>
      <div
        className={`col-span-${col} ${description ? "flex gap-5" : ""} py-2 `}
      >
        <Checkbox onChange={onChange} checked={value} />
        <div className="col-span-2 text-xs flex justify-start items-center  h-full  text-gray-700 ">
          {description}
        </div>
      </div>
    </>
  );
};

export default CheckboxField;
