import React from "react";
import Icon from "@components/core-components/icon";

const FilterButton: React.FC = () => {
  const [closeFilters, setCloseFilters] = React.useState(true);

  return (
    <div className="flex flex-col self-stretch my-auto w-40">
      <button
        onClick={() => ""}
        className="flex items-center justify-between h-10 w-40 bg-primary text-white rounded-lg hover:bg-primary-hover transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-400 font-semibold text-sm"
      >
        <span className="flex items-center p-[15px] space-x-1 justify-center w-full ">
          <Icon
            iconName="filter"
            svgProps={{
              width: "21px",
              height: "21px",
            }}
          />
          <span className="text-[13px]">
            {!closeFilters ? "Open Filters" : "Close Filters"}
          </span>
        </span>
        <div className="flex items-center justify-center w-8 h-10 bg-indigo-600 rounded-r-lg">
          <Icon
            svgProps={{
              width: "13px",
            }}
            iconName="whiteArrow"
            className={`transition-transform duration-300  ${
              closeFilters ? "rotate-180" : "rotate-0"
            }`}
          />
        </div>
      </button>
    </div>
  );
};

export default FilterButton;
