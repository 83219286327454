import React, { useState } from "react";
import Icon from "@components/core-components/icon";
import PInput from "@components/core-components/input";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

function AddCurrencyModalContent() {
  const [searchText, setSearchText] = useState("");
  const [addedCurrencies, setAddedCurrencies] = useState([
    { code: "EUR", name: "Euro" },
    { code: "USD", name: "US Dollar" },
    { code: "INR", name: "Indian Rupee" },
    { code: "TRY", name: "Turkish Lira" },
  ]);

  const [allCurrencies, setAllCurrencies] = useState([
    { code: "EUR", name: "Euro", isAdded: true },
    { code: "USD", name: "US Dollar", isAdded: true },
    { code: "INR", name: "Indian Rupee", isAdded: true },
    { code: "TRY", name: "Turkish Lira", isAdded: true },
    { code: "GBP", name: "Sterling", isAdded: false },
    { code: "AUD", name: "Australian Dollar", isAdded: false },
    { code: "CHF", name: "Swiss Franc", isAdded: false },
  ]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleAddCurrency = (currency: any) => {
    setAddedCurrencies((prevAdded) => [
      ...prevAdded,
      { code: currency.code, name: currency.name },
    ]);
    setAllCurrencies((prevCurrencies) =>
      prevCurrencies.map((curr) =>
        curr.code === currency.code ? { ...curr, isAdded: true } : curr
      )
    );
  };

  const handleRemoveCurrency = (currency: any) => {
    setAddedCurrencies((prevAdded) =>
      prevAdded.filter((curr) => curr.code !== currency.code)
    );
    setAllCurrencies((prevCurrencies) =>
      prevCurrencies.map((curr) =>
        curr.code === currency.code ? { ...curr, isAdded: false } : curr
      )
    );
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const items = Array.from(addedCurrencies);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAddedCurrencies(items);
  };

  const filteredCurrencies = allCurrencies.filter(
    (currency) =>
      currency.code.toLowerCase().includes(searchText.toLowerCase()) ||
      currency.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div className="flex flex-row gap-4 px-8 py-6 w-[947px]">
      <div className="flex flex-col gap-4 max-w-[414px] w-full">
        <span className="text-body-reg-16 font-semibold text-gray-900 leading-none max-w-[414px] w-full">
          Added Currencies
        </span>

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="addedCurrencies" direction="vertical">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="flex flex-col w-full gap-3"
              >
                {addedCurrencies.map((currency, index) => (
                  <Draggable
                    key={currency.code}
                    draggableId={currency.code}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="flex flex-row items-center w-full gap-3 bg-white rounded"
                      >
                        <div className="flex items-center w-10 h-10 justify-center rounded border border-gray-300">
                          <span className="text-body-reg-16 font-semibold text-gray-900 leading-none">
                            {index + 1}
                          </span>
                        </div>
                        <div className="flex items-center w-[258px] h-10 justify-start px-4 rounded border border-gray-300">
                          <span className="text-body-reg-13 font-semibold text-gray-700 leading-none">
                            {currency.code}{" "}
                            <span className="font-normal">
                              - {currency.name}
                            </span>
                          </span>
                        </div>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleRemoveCurrency(currency);
                          }}
                          className="flex items-center w-10 h-10 justify-center rounded border border-gray-300"
                        >
                          <Icon
                            iconName="trashCan"
                            className="text-gray-700 w-5 h-5"
                          />
                        </button>
                        <button
                          type="button"
                          className="flex items-center w-10 h-10 justify-center rounded border border-gray-300"
                        >
                          <Icon
                            iconName="move"
                            className="text-gray-700 w-5 h-5 mt-3"
                          />
                        </button>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <hr className="w-px h-auto opacity-50 bg-[#CECCE4]" />
      <div className="flex flex-col gap-2 max-w-[414px] w-full">
        <div className="flex flex-col gap-3 max-w-[414px] w-full">
          <span className="text-body-reg-16 font-semibold text-gray-900 leading-none max-w-[414px] w-full">
            Currencies
          </span>
          <PInput
            placeholder="Search currencies..."
            iconName="search"
            showIcon={true}
            value={searchText}
            id="filter-text-box"
            onChange={handleSearch}
            className="h-10 w-full md:w-80 sm:w-70 lg:w-96"
            wrapperClassNames="relative"
          />
          <hr className="w-[416px] border-t border-dashed border-[#CECCE4] opacity-50" />

          {filteredCurrencies.map((currency) => (
            <div
              key={currency.code}
              className="flex flex-col items-center w-full gap-3"
            >
              <div className="flex flex-row items-center justify-between w-full gap-3">
                <div className="flex flex-row items-center h-full gap-2">
                  <Icon
                    svgProps={{
                      width: 10,
                      height: 10,
                    }}
                    iconName={currency.isAdded ? "ellipse10" : "ellipse3"}
                    className="w-3 h-3"
                  />
                  <span className="text-body-reg-13 font-semibold text-gray-700 leading-none flex items-center">
                    {currency.code}{" "}
                    <span className="font-normal">- {currency.name}</span>
                  </span>
                </div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (!currency.isAdded) {
                      handleAddCurrency(currency);
                    }
                  }}
                  className={`w-20 h-7 ${
                    currency.isAdded
                      ? "bg-primary text-white hover:bg-primary-hover"
                      : "bg-primary-light text-indigo-500 hover:bg-indigo-200"
                  } flex flex-row justify-center items-center rounded-md font-medium text-body-reg-12`}
                >
                  {currency.isAdded ? "Added" : "Add"}
                </button>
              </div>
              <hr className="w-[416px] border-t border-dashed border-[#CECCE4] opacity-50" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AddCurrencyModalContent;
