import { useEffect, useState } from "react";
import { PDataGrid } from "@components/core-components/grid/PDataGrid";
import {
  broadCastMessagesColDef,
  personalMessagesColDef,
  recievedMessagesColDef,
  sentMessagesColDef,
  mockData,
} from "./colDefs";
import ContentWrapper from "pages/players/details/components/ContentWrapper";
import Subheader from "pages/players/details/components/Subheader";

export default function Messages() {
  const [activeTab, setActiveTab] = useState("Recieved");
  const [colDefs, setColDefs] = useState(recievedMessagesColDef);
  useEffect(() => {
    switch (activeTab) {
      case "Recieved":
        setColDefs(recievedMessagesColDef);
        // setData
        break;
      case "Personal Messages Pending":
        setColDefs(personalMessagesColDef);
        // setData
        break;
      case "Broadcast Messages Pending":
        setColDefs(broadCastMessagesColDef);
        // setData
        break;
      case "Sent":
        setColDefs(sentMessagesColDef);
        // setData
        break;
      default:
        break;
    }
  }, [activeTab]);

  return (
    <>
      <Subheader
        tabs={[
          "Recieved",
          "Personal Messages Pending",
          "Broadcast Messages Pending",
          "Sent",
        ]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <ContentWrapper>
        <PDataGrid rowData={mockData} colDefs={colDefs} />
      </ContentWrapper>
    </>
  );
}
