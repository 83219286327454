import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import authService from "api/auth/AuthService";

export const authenticationLogin = createAsyncThunk(
  "auth/login",
  async (
    credentials: { userId: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await authService.login(
        credentials.userId,
        credentials.password
      );
      return { response, credentials };
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkAuthStatus = createAsyncThunk(
  "auth/checkAuthStatus",
  async (_, { rejectWithValue }) => {
    try {
      const response = await authService.checkAuthStatus();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      const response = await authService.logout();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

interface AuthenticationState {
  userId: string | null;
  loading: boolean;
  error: string | null;
  isAuthenticated: boolean;
}

const initialState: AuthenticationState = {
  userId: null,
  loading: false,
  error: null,
  isAuthenticated: false,
};

export const authenticationSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state: AuthenticationState) => {
      state.isAuthenticated = false;
      state.userId = null;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(
      authenticationLogin.pending,
      (state: AuthenticationState) => {
        state.loading = true;
        state.error = null;
        state.isAuthenticated = false;
      }
    );
    builder.addCase(
      authenticationLogin.fulfilled,
      (state: AuthenticationState, action: PayloadAction<any>) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.userId = action.payload.credentials.userId;
        state.error = null;
      }
    );
    builder.addCase(
      authenticationLogin.rejected,
      (state: AuthenticationState, action: PayloadAction<any>) => {
        state.loading = false;
        state.error =
          action.payload || "Something went wrong. Please try again.";
        state.isAuthenticated = false;
      }
    );

    builder.addCase(checkAuthStatus.pending, (state: AuthenticationState) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      checkAuthStatus.fulfilled,
      (state: AuthenticationState, action: PayloadAction<any>) => {
        state.loading = false;
        state.isAuthenticated = true;
      }
    );
    builder.addCase(
      checkAuthStatus.rejected,
      (state: AuthenticationState, action: PayloadAction<any>) => {
        state.loading = false;
        state.isAuthenticated = false;
      }
    );
    builder.addCase(logoutUser.pending, (state: AuthenticationState) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(logoutUser.fulfilled, (state: AuthenticationState) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.userId = null;
    });
    builder.addCase(
      logoutUser.rejected,
      (state: AuthenticationState, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      }
    );
  },
});

export const { logout } = authenticationSlice.actions;
export default authenticationSlice.reducer;
