import BDatePicker from "@components/core-components/datePicker";
import React from "react";
import FormLabel from "./FormLabel";

const DatePickerField = ({
  label,
  value,
  onChange,
}: {
  label: string;
  value: { startDate: Date | null; endDate: Date | null };
  onChange: (date: any) => void;
}) => (
  <>
    <FormLabel label={label} />
    <div className="col-span-3 mr-2">
      <BDatePicker
        placeholder="dd/mm/yyyy"
        value={value}
        onChange={onChange}
        className="h-[40px] justify-center"
      />
    </div>
  </>
);
export default DatePickerField;
