import React, { useState } from "react";
import FinancialCard from "./FinancialCard";
import FinancialBreakdown from "./FinancialBreakdown";
import PIDropDown from "@components/core-components/dropdown";

interface FinancialOverviewProps {
  balance: string;
  bonusWallet: string;
  profitability: string;
  grossGamingRevenue: string;
  sportsBookAmount: string;
  casinoAmount: string;
  exchangeAmount: string;
}

const FinancialOverview: React.FC<FinancialOverviewProps> = ({
  balance,
  bonusWallet,
  profitability,
  grossGamingRevenue,
  sportsBookAmount,
  casinoAmount,
  exchangeAmount,
}) => {
  const [selectedValue, setSelectedValue] = useState("EUR");
  const financialData = [
    {
      color: "bg-[#45A9EF]",
      amount: balance,
      label: "Balance/EUR, Approximation",
      dropDown: (
        <PIDropDown
          options={[
            { value: "EUR", label: "EUR" },
            { value: "USD", label: "USD" },
            { value: "INR", label: "INR" },
            { value: "TRY", label: "TRY" },
          ]}
          value={selectedValue}
          onChange={(value) => setSelectedValue(value)}
          dropDownlistClassNames="w-[185px] top-[80px] right-[0px]"
          className="h-[25px] w-[25px]"
        />
      ),
    },
    { color: "bg-[#009C2C]", amount: bonusWallet, label: "Bonus Wallet" },
    {
      color: "bg-[#FFCF45]",
      amount: profitability,
      label: "Player Profitability For Us",
    },
    {
      color: "bg-[#F1416C]",
      amount: grossGamingRevenue,
      label: "Gross Gaming Revenue",
    },
  ];

  return (
    <section className="flex flex-col grow shrink min-w-[240px] w-full max-w-[1179px] max-md:max-w-full">
      <div className="flex flex-row gap-3 items-center w-full max-md:max-w-full relative">
        {financialData.map((data, index) => (
          <FinancialCard key={index} {...data} />
        ))}
      </div>
      <FinancialBreakdown
        sportsBookAmount={sportsBookAmount}
        casinoAmount={casinoAmount}
        exchangeAmount={exchangeAmount}
      />
    </section>
  );
};

export default FinancialOverview;
