import Icon from "@components/core-components/icon";

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

const isValidDate = (value: any) => {
  if (typeof value !== "string") return false;

  const dateRegex = /^\d{4}-\d{2}-\d{2}/;

  return dateRegex.test(value) && !isNaN(Date.parse(value));
};

const getFriendlyFieldName = (key: string) => {
  switch (key) {
    case "isRegistered":
      return "Registered";

    case "playerId":
    case "id":
      return "Id";
    case "lastLogin":
      return "Last Login Date";
    case "dateOfBirth":
      return "Date of Birth";
    case "isValidated":
      return "Validated";
    case "registrationDate":
      return "Registration Date";
    case "country":
      return "Country";
    case "gender":
      return "Gender";
    case "email":
      return "Email";
    case "mobile":
      return "Mobile";
    case "zip":
      return "Zip Code";
    case "language":
      return "Language";
    case "marketingCode":
      return "Marketing Code";
    case "revenueScheme":
      return "Revenue Scheme";
    default:
      return key;
  }
};

const renderFilterValue = (key: string, value: any) => {
  if (
    key === "registrationDateComp" ||
    key === "lastLoginComp" ||
    key === "lastLoginDateComp" ||
    key === "dateOfBirthComp"
  )
    return null;

  const friendlyKey = getFriendlyFieldName(key);

  if (isValidDate(value)) {
    return `${friendlyKey}: ${formatDate(value)}`;
  }

  if (typeof value === "object" && value !== null) {
    return `${friendlyKey}: ${value.name || value.id || value}`;
  }

  return `${friendlyKey}: ${value}`;
};

function FilterTags({
  showTags,
  filters,
  reset,
  removeFilter,
}: {
  showTags: boolean;
  filters: { [key: string]: any };
  reset: () => void;
  removeFilter: (filterKey: string) => void;
}) {
  return (
    <div
      className={`transition-all w-full  duration-500 ease-in-out transform ${
        showTags
          ? "max-h-45 opacity-100 translate-y-0"
          : "max-h-0 opacity-0 translate-y-2"
      }`}
      style={{
        transitionProperty: "max-height, opacity, transform",
        height: showTags ? "100%" : "0",
      }}
    >
      <div className="flex justify-start gap-2 items-center">
        <p className="font-semibold">Filters Applied:</p>
        <button className="text-primary underline" onClick={reset}>
          Clear All
        </button>
      </div>

      <div className="flex flex-wrap gap-2 my-6">
        {filters &&
          Object.entries(filters).map(([key, value]) => {
            const filterDisplay = renderFilterValue(key, value);
            return (
              filterDisplay && (
                <div
                  key={key}
                  className="bg-gray-600 text-white  p-2 rounded-md flex text-[13px] items-center space-x-2"
                >
                  <span>{filterDisplay}</span>
                  <button
                    className="text-gray-500 hover:text-gray-700 flex justify-center items-center"
                    onClick={() => removeFilter(key)}
                  >
                    <Icon
                      svgProps={{ width: 13, height: 13 }}
                      iconName="whiteCancel"
                      className="  flex justify-center items-center"
                    />
                  </button>
                </div>
              )
            );
          })}
      </div>
    </div>
  );
}

export default FilterTags;
