import React, { ReactNode } from "react";
import FinancialCardBackgroundImage from "../../../../assets/images/item13.png";

interface FinancialCardProps {
  color: string;
  amount: string;
  label: string;
  dropDown?: ReactNode;
}

const FinancialCard: React.FC<FinancialCardProps> = ({
  color,
  amount,
  label,
  dropDown,
}) => {
  return (
    <div
      className="flex flex-row h-[74px] max-w-[285px] relative shrink items-center justify-between self-stretch p-5 bg-white rounded-lg border border-gray-300 border-solid shadow-[0px_3px_4px_0px_rgba(0,0,0,0.03)] w-full"
      style={{
        backgroundImage: `url(${FinancialCardBackgroundImage})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left",
      }}
    >
      <div className="flex z-0 gap-4 items-center w-full">
        <div className={`flex shrink-0 h-11 ${color} rounded w-[5px]`} />
        <div className="flex flex-col">
          <div className="text-xl font-semibold leading-tight text-slate-900">
            {amount}
          </div>
          <div className="mt-1 text-xs font-medium leading-none text-slate-500">
            {label}
          </div>
        </div>
      </div>
      {dropDown}
    </div>
  );
};

export default FinancialCard;
