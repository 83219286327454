import { ReactNode } from "react";

interface ContentWrapperProps {
  children: ReactNode;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({ children }) => {
  return (
    <div className="h-[450px] px-6 bg-white pb-6 rounded-b-[10px]">
      {children}
    </div>
  );
};

export default ContentWrapper;
