import React from "react";
import FilterHeader from "./components/FilterHeader";
import GridFilter from "./components/GridFilter";
import { PDataGrid } from "@components/core-components/grid/PDataGrid";
import Icon from "@components/core-components/icon";

function Bonus() {
  const gridRef = React.useRef<any>(null);
  const [filterLoading, setFilterLoading] = React.useState(false);

  const [quickFilterText, setQuickFilterText] = React.useState("");
  const [filterParams, setFilterParams] = React.useState<any>({});
  const handleApplyFilters = (filters: any) => {
    setFilterParams(filters);
  };
  const [closeFilters, setCloseFilters] = React.useState(false);
  const onFilterTextBoxChanged = (e: any) => {
    setQuickFilterText(e.target.value);
  };
  const handleExportCSV = () => {
    console.log("Export CSV");
  };
  const customStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
  const testData: any = [
    {
      id: 1,
      name: "Live Casino Bonus",
      description: "Lorem ipsum dolor sit amet",
      type: "Deposit",
      startDate: "2021/09/01",
      endDate: "2021/09/30",
      status: true,
      currency: "Pounds Sterling",
      cost: {
        currency: "EUR",
        amount: 1000,
        symbol: "€",
      },
    },
    {
      id: 2,
      name: "Live Casino Bonus",
      description: "Lorem ipsum dolor sit amet",
      type: "Deposit",
      startDate: "2021/09/01",
      endDate: "2021/09/30",
      status: false,
      currency: "Pounds Sterling",
      cost: {
        currency: "EUR",

        amount: 1000,
        symbol: "€",
      },
    },
    {
      id: 3,
      name: "Live Casino Bonus",
      description: "Lorem ipsum dolor sit amet",
      type: "Deposit",
      startDate: "2021/09/01",
      endDate: "2021/09/30",
      status: true,
      currency: "Pounds Sterling",
      cost: {
        currency: "EUR",
        amount: 1000,
        symbol: "€",
      },
    },
  ];
  const colDefs: any = [
    {
      headerName: "Name",
      field: "name",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
      flex: 1,
      cellStyle: {
        ...customStyles,
        color: "var(--Primary-Primary, #666CFF)",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "normal",
        textDecoration: "underline",
        textUnderlineOffset: "2px",
      },
    },
    {
      headerName: "Description",
      field: "description",
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      flex: 2,
      cellStyle: customStyles,
    },

    {
      headerName: "Type",
      field: "type",
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      flex: 1,
      cellStyle: customStyles,
    },
    {
      headerName: "Start Date",
      field: "startDate",
      sortable: true,
      filter: "agDateColumnFilter",
      floatingFilter: true,
      flex: 1,
      cellStyle: customStyles,
    },
    {
      headerName: "End Date",
      field: "endDate",
      sortable: true,
      filter: "agDateColumnFilter",
      floatingFilter: true,
      flex: 1,
      cellStyle: customStyles,
    },

    {
      headerName: "Currency",
      field: "currency",
      sortable: true,

      floatingFilter: true,
      filter: "agTextColumnFilter",
      flex: 1,
      cellStyle: customStyles,
    },
    {
      headerName: "Cost To Date",
      field: "cost",
      sortable: true,
      floatingFilter: true,
      flex: 1,
      cellStyle: {
        fontWeight: "bold",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        color: "var(--dark, #282A42)",
      },

      filter: "agNumberColumnFilter",
      valueFormatter: (params: any) => {
        return `${
          params.data.cost.symbol
        } ${params.data.cost.amount.toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      headerName: "Live",
      field: "status",
      sortable: true,
      floatingFilter: true,
      filter: true,
      flex: 0.5,
      cellStyle: { display: "flex", justifyContent: "center" },
      cellRenderer: (params: any) => {
        const isLive = params.value;
        return isLive ? (
          <Icon
            svgProps={{
              width: 26,
              height: 26,
            }}
            iconName="checkbox"
          />
        ) : (
          <Icon
            svgProps={{
              width: 26,
              height: 26,
            }}
            iconName="unCheckedbox"
          />
        );
      },
    },
    {
      headerName: "Process",
      width: 108,
      autoHeight: true,
      minWidth: 90,
      filter: false,
      floatingFilter: false,
      pinned: "right",
      sortable: false,
      cellClass: "",
      cellRenderer: (params: any) => (
        <button
          className="w-20 h-7 bg-danger-light flex flex-row justify-center items-center rounded-md text-danger font-medium text-body-reg-12 hover:bg-danger hover:text-white"
          onClick={() => console.log("Delete")}
        >
          Delete
        </button>
      ),
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  ];
  return (
    <section className="flex flex-col pl-6 pr-6 pt-6 pb-9 shadow-[0px_2px_6px_rgba(0,0,0,0.1)] bg-white">
      <FilterHeader
        handleExportCSV={handleExportCSV}
        handleSearch={onFilterTextBoxChanged}
        searchText={quickFilterText}
        closeFilters={closeFilters}
        setCloseFilters={setCloseFilters}
      />{" "}
      <div className="flex flex-col flex-grow ">
        <GridFilter
          filterParams={filterParams}
          setShowSimpleFilters={setCloseFilters}
          onApplyFilters={handleApplyFilters}
          showSimpleFilters={closeFilters}
        />
      </div>
      <div className="flex-grow ">
        <PDataGrid
          loading={filterLoading}
          gridRef={gridRef}
          colDefs={colDefs}
          showFooter={false}
          rowData={testData}
        />
      </div>
    </section>
  );
}

export default Bonus;
