import Checkbox from "@components/core-components/checkbox";
import Icon from "@components/core-components/icon";
import Modal from "@components/core-components/modal";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
const MAX_FILE_SIZE = 3 * 1024 * 1024; // 3 MB

export default function PrivacyInfo() {
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploadStatus, setUploadStatus] = useState<string>("idle");
  const [isOpen, setModalOpen] = useState<boolean>(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string>("");
  const [localImagePreview, setLocalImagePreview] = useState<string>("");
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file.size > MAX_FILE_SIZE) return;
    setLocalImagePreview(URL.createObjectURL(file));
    setUploadStatus("uploading");
    const formData = new FormData();
    acceptedFiles.forEach((file: any) => {
      formData.append("files", file);
    });
    axios
      .post("", formData, {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          if (total) {
            let percent = Math.floor((loaded * 100) / total);
            setUploadProgress(percent);
          }
        },
      })
      .then((response) => {
        setUploadProgress(0);
        setUploadedImageUrl(response.data.imageUrl);
        setUploadStatus("success");
      })
      .catch((error) => {
        setUploadStatus("error");
        console.error("error", error);
      });
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
      "image/gif": [],
    },
  });

  const handleCloseModal = () => setModalOpen(!isOpen);

  return (
    <div className="w-full bg-white">
      <div className="rounded-[10px] mb-8 p-6 pt-4 rounded-b-[10px] shadow-lg w-full">
        <div className="w-full xl:w-1/2">
          <div className="flex flex-row justify-between w-[340px]">
            <p className="text-gray-700 font-medium text-body-reg-14">
              Is Validated
            </p>
            <Checkbox checked />
          </div>
          <hr className="my-3 border-gray-300 w-full" />
          <p className="text-gray-700 font-medium text-body-reg-14 mb-4">
            Verification Image
          </p>
          <div
            {...getRootProps()}
            className={`flex flex-col items-center justify-center border-2 bg-secondary-light  border-secondary  border-dashed text-center w-[200px] h-[167px] p-6 shadow-lg rounded-xl gap-3 ${
              isDragActive && "border-gray-300"
            }`}
          >
            <Icon
              iconName="fileupload"
              svgProps={{ width: 67, height: 67 }}
              onClick={handleCloseModal}
            />
            <input {...getInputProps()} />
            <p className="text-heading-16 font-semibold text-gray-900">
              Upload Image
            </p>
            <p className="text-body-bold-14 text-gray-500 ">
              Drag & Drop files here
            </p>
          </div>
          <div className="h-10 flex justify-end mt-7">
            <button
              type="button"
              className="bg-gray-200 text-gray-800 px-4 py-2 font-semibold rounded-lg  hover:bg-gray-300 w-[139px] text-body-reg-13 h-full"
            >
              Reset
            </button>
            <button className="bg-indigo-500 text-white px-4 py-2 font-semibold rounded-lg hover:bg-indigo-600 w-[139px] text-body-reg-13 h-full ml-3">
              Save
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        title="Verification Image"
        onClose={handleCloseModal}
        content={
          <div className="flex flex-col gap-4 px-8 py-6">
            {uploadStatus.includes("idle") ? (
              <>
                <div
                  className="flex flex-col gap-3 items-center cursor-pointer justify-center h-[372px] w-[437px] border-dashed border-gray-700 border rounded flex-shrink-0"
                  {...getRootProps()}
                >
                  <Icon
                    iconName="fileupload"
                    svgProps={{ width: 67, height: 67 }}
                  />
                  <input {...getInputProps()} />
                  <p className="text-heading-16 font-semibold text-gray-900">
                    Upload Image
                  </p>
                  <p className="text-body-bold-14 text-gray-500 ">
                    Drag & Drop files here
                  </p>
                  <button
                    className="flex flex-col items-center justify-center w-[140px] h-10 bg-primary rounded-lg text-white text-body-reg-13 font-semibold"
                    onClick={open}
                  >
                    Browse
                  </button>
                </div>
              </>
            ) : (
              <div className="flex flex-col items-center justify-center h-[372px] w-[437px] border-dashed border-gray-700 border rounded flex-shrink-0 relative">
                {uploadStatus.includes("uploading") ? (
                  <div className="flex flex-col items-center justify-center w-[330px]">
                    <div className="flex flex-col justify-between items-center">
                      <span className="text-heading-24 font-semibold text-gray-900 mb-1">
                        {uploadProgress}%
                      </span>
                      <span className="text-body-reg-14 font-medium text-gray-500">
                        Uploading
                      </span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-4 my-4">
                      <div
                        className="bg-[#17B74E] h-4 rounded-full"
                        style={{ width: `${uploadProgress}%` }}
                      ></div>
                    </div>
                  </div>
                ) : (
                  uploadStatus.includes("success") && (
                    <>
                      <Icon
                        iconName="trash"
                        svgProps={{ height: 20, width: 20 }}
                        className="cursor-pointer absolute right-6 top-6"
                      />
                      <div className="h-[224px] w-[224px] flex items-center justify-center p-4">
                        <img
                          src={uploadedImageUrl}
                          alt="Preview"
                          className="object-cover h-auto w-auto"
                        />
                      </div>
                    </>
                  )
                )}
              </div>
            )}
            <span className="w-full flex flex-row justify-center font-normal text-body-reg-12 text-gray-500">
              Maximum upload file size: 3MB ( JPG, PNG and GIF)
            </span>
          </div>
        }
        footer={
          <>
            <button
              type="button"
              className="bg-gray-200 text-gray-900 px-4 py-2 rounded-lg hover:bg-gray-300 text-body-reg-13 font-semibold w-[140px] h-[40px]"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
            <button className="bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600 w-[140px] h-[40px] font-semibold text-body-reg-13  ml-3">
              Save
            </button>
          </>
        }
      />
    </div>
  );
}
