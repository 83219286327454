import { useCallback, useRef, useState, useEffect } from "react";
import { PDataGrid } from "@components/core-components/grid/PDataGrid";
import { useBaseQuery } from "api/core/useBaseQuery";
import { AgGridReact } from "ag-grid-react";
import GridFilter from "pages/players/list/components/GridFilter";
import DetailCellRenderer from "pages/players/list/components/DetailCellRenderer";
import { PLAYERS_API_URLS } from "config/apiUrls";
import { dateComparator } from "utils/table";

function NotesTable({ searchText }: { searchText: string }) {
  const [closeFilters, setCloseFilters] = useState(true);

  const { data, refetch } = useBaseQuery<[]>({
    endpoint: PLAYERS_API_URLS.GET_PLAYERS,
  });

  const gridRef = useRef<AgGridReact>(null);

  const handleRemove = (params: any) => {
    const id = params.data?.id;
  };

  const [colDefs, setColDefs] = useState<any>([
    {
      headerName: "DATE",
      field: "lastLogin",
      autoHeight: true,
      floatingFilter: true,
      filter: "agDateColumnFilter",
      cellClass: "ellipsis-cell",
      filterParams: {
        comparator: dateComparator,
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      },
      width: 195,
      cellRenderer: (params: any) => (
        <div className="flex flex-row justify-center items-center gap-2">
          <span className="text-body-reg-14 text-gray-900">{params.value}</span>
        </div>
      ),
    },
    {
      headerName: "CREATED BY",
      field: "username",
      width: 230,
      autoHeight: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cellClass: "ellipsis-cell text-primary",
      cellStyle: {
        overflowX: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        color: "var(--Gray-Gray-900, #282A42)",
      },
    },
    {
      headerName: "NOTE",
      field: "note",
      filter: "agTextColumnFilter",
      width: 1151,
      autoHeight: true,
      floatingFilter: true,
      cellClass: "ellipsis-cell",
      cellStyle: {
        overflowX: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      },
      cellRenderer: (params: any) => (
        <div className="flex flex-row justify-center items-center gap-2">
          <span className="text-body-reg-14 text-gray-900">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
            non maximus leo.
          </span>
        </div>
      ),
    },
    {
      headerName: "PROCESS",
      field: "process",
      width: 214,
      autoHeight: true,
      filter: true,
      floatingFilter: false,
      cellClass: "ellipsis-cell",
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      cellRenderer: (params: any) => (
        <div className="flex flex-row justify-center items-center gap-2">
          <button
            className="w-20 h-7 bg-primary-light text-primary flex flex-row justify-center items-center rounded-md font-medium text-body-reg-12 hover:bg-primary-hover hover:text-white"
            onClick={() => handleRemove(params)}
          >
            Edit
          </button>
          <button
            className="w-20 h-7 bg-danger-light flex flex-row justify-center items-center rounded-md text-danger font-medium text-body-reg-12 hover:bg-danger hover:text-white"
            onClick={() => handleRemove(params)}
          >
            Remove
          </button>
        </div>
      ),
    },
  ]);

  const onGridReady = useCallback(
    (params: any) => {
      params.api.sizeColumnsToFit();
      if (searchText) {
        const filterModel = {
          username: {
            type: "contains",
            filter: searchText,
          },
        };
        params.api.setFilterModel(filterModel);
      }
    },
    [searchText]
  );

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      const filterModel = {
        username: {
          type: "contains",
          filter: searchText,
        },
      };
      gridRef.current.api.setFilterModel(filterModel);
    }
  }, [searchText]);

  return (
    <div className="bg-white flex flex-col w-full max-md:max-w-full">
      <div className="flex flex-col z-[9]">
        <GridFilter
          showSimpleFilters={!closeFilters}
          onApplyFilters={() => {}}
        />
        <div>
          <PDataGrid
            gridRef={gridRef}
            DetailCellRenderer={DetailCellRenderer}
            colDefs={colDefs}
            rowData={data || []}
            onGridReady={onGridReady}
          />
        </div>
      </div>
    </div>
  );
}

export default NotesTable;
