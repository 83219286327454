import { useState } from "react";
import BSelect from "@components/core-components/select";

import FilterTags from "../../players/list/components/FilterTags";

type InputType = {
  placeholder?: string;
  value: string;
  id: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className: string;
};
type MarketingCode = {
  id: number;
  code: string;
  affiliate_id: number;
  brand_id: number;
};

function GridFilter({
  showSimpleFilters,
  filterParams,
  setShowSimpleFilters,
  onApplyFilters,
}: Readonly<{
  filterParams?: { [key: string]: any };
  setShowSimpleFilters?: (value: boolean) => void;
  showSimpleFilters: boolean;
  onApplyFilters: (filters: any) => void;
}>) {
  const [currency, setCurrency] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [status, setStatus] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [bonusScheme, setBonusScheme] = useState<{
    id: string;
    name: string;
  } | null>(null);

  const [showTags, setShowTags] = useState(false);

  const removeFilter = (filterKey: string) => {
    if (filterParams) {
      const newFilters = { ...filterParams };

      delete newFilters[filterKey];

      switch (filterKey) {
        case "currency":
          setCurrency(null);
          break;
        case "status":
          setStatus(null);
          break;
        case "bonusScheme":
          setBonusScheme(null);
          break;

        default:
          break;
      }

      if (Object.keys(newFilters).length === 0) {
        setShowTags(false);
        setShowSimpleFilters && setShowSimpleFilters(true);
      } else {
        onApplyFilters(newFilters);
      }
    }
  };

  const handleReset = () => {
    setCurrency(null);
    setStatus(null);
    setBonusScheme(null);
    onApplyFilters({});
  };
  const handleApplyFilters = () => {
    setShowTags(true);
    setShowSimpleFilters && setShowSimpleFilters(false);

    const filters = {
      ...(currency && { currency: currency.id }),
      ...(status && { status: status.id }),
      ...(bonusScheme && { bonusScheme: bonusScheme.id }),
    };

    onApplyFilters(filters);
  };

  //   const mapOptions = (data: any, labelTitle: string) => {
  //     return data?.map((item: any) => ({
  //       value: item.id.toString(),
  //       label: item[labelTitle],
  //     }));
  //   };

  return (
    <>
      <div
        className={`transition-all w-full z-40 duration-500 ease-in-out transform ${
          showSimpleFilters
            ? "opacity-100 max-h-full pointer-events-auto"
            : "opacity-0 max-h-0 pointer-events-none"
        }`}
        style={{
          transitionProperty: "max-height, opacity, transform",
          maxHeight: showSimpleFilters ? "100%" : "0",
        }}
      >
        <div className="grid grid-cols-5 gap-3 mt-1 mb-0">
          <BSelect
            options={[
              {
                value: "true",
                label: "True",
              },
              {
                value: "false",

                label: "False",
              },
            ]}
            value={bonusScheme?.id ?? ""}
            onChange={(e: any) =>
              setBonusScheme({
                id: e.target.value,
                name: e.target.value,
              })
            }
            className="w-full mb-3"
            placeholder="Bonus Scheme"
          />
          <BSelect
            options={[
              {
                value: "EUR",
                label: "EUR",
              },
              {
                value: "USD",
                label: "USD",
              },
              {
                value: "GBP",
                label: "GBP",
              },
            ]}
            value={currency?.id ?? ""}
            onChange={(e: any) => {
              setCurrency({
                id: e.target.value,
                name: e.target.value,
              });
            }}
            className="w-full mb-3"
            placeholder="Currency"
          />
          <BSelect
            options={[
              {
                value: "true",
                label: "True",
              },
              {
                value: "false",

                label: "False",
              },
            ]}
            value={status?.id ?? ""}
            onChange={(e: any) =>
              setStatus({
                id: e.target.value,
                name: e.target.value,
              })
            }
            className="w-full mb-3"
            placeholder="Status"
          />
        </div>

        <div className="flex justify-end my-4 space-x-3">
          <button
            onClick={handleReset}
            className={`flex justify-center h-10 w-[139px] items-center bg-gray-300 text-black p-4 rounded-md hover:bg-gray-400 transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-400 space-x-2 font-semibold text-sm`}
          >
            Reset
          </button>
          <button
            onClick={handleApplyFilters}
            className={`flex justify-center h-10 w-[139px] items-center bg-indigo-500 text-white p-4 rounded-md hover:bg-indigo-600 transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-400 space-x-2 font-semibold text-sm`}
          >
            Apply
          </button>
        </div>
      </div>

      {setShowSimpleFilters && (
        <FilterTags
          filters={filterParams || []}
          removeFilter={removeFilter}
          reset={() => {
            setShowSimpleFilters && setShowSimpleFilters(true);
            setShowTags(false);
            handleReset();
          }}
          showTags={showTags}
        />
      )}
    </>
  );
}

export default GridFilter;
