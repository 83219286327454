import { useState } from "react";
import PInput from "@components/core-components/input";
import BSelect from "@components/core-components/select";
import CheckBox from "@components/core-components/checkbox";
import Radio from "@components/core-components/radio/Index";
import { useBaseQuery } from "api/core/useBaseQuery";
import BDatePicker from "@components/core-components/datePicker";
import { DateValueType } from "react-tailwindcss-datepicker";
import {
  COUNTRY_API_URLS,
  LANGUAGES_API_URLS,
  REVENUE_SCHEMES_API_URLS,
} from "config/apiUrls";
import { MARKETİNG_CODES_API_URLS } from "config/apiUrls";

type Region = {
  id: number;
  name: string;
};

type MarketingCode = {
  id: number;
  code: string;
  affiliate_id: number;
  brand_id: number;
};

type RevueScheme = {
  name: string;
  description: string;
};

type Language = {
  name: string;
  code: string;
};

const mapOptions = (data: any, labelTitle: string) => {
  return data?.map((item: any) => ({
    value: item.id.toString(),
    label: item[labelTitle],
  }));
};

export default function PlayerInfo() {
  const [checkedCountry, setCheckedCountry] = useState("");
  const [checkedMarketingCode, setMarketingCode] = useState("");
  const [revenueScheme, setRevenueScheme] = useState("");
  const [language, setLanguage] = useState("");
  const [birthdayDate, setBirthdayDate] = useState<DateValueType | null>(null);
  const { data: countries } = useBaseQuery<Region[]>({
    endpoint: COUNTRY_API_URLS.GET_COUNTRIES,
  });

  const { data: marketingCode } = useBaseQuery<MarketingCode[]>({
    endpoint: MARKETİNG_CODES_API_URLS.GET_MARKETING_CODES,
  });

  const { data: revenueSchemes } = useBaseQuery<RevueScheme[]>({
    endpoint: REVENUE_SCHEMES_API_URLS.GET_REVENUE_SCHEMES,
  });
  const { data: languages } = useBaseQuery<Language[]>({
    endpoint: LANGUAGES_API_URLS.GET_LANGUAGES,
  });

  const countriesOptions = mapOptions(countries, "name");
  const marketingCodesOptions = mapOptions(marketingCode, "code");
  const revenueSchemesOptions = mapOptions(revenueSchemes, "name");
  const languagesOptions = mapOptions(languages, "name");

  return (
    <div className="h-[calc(100vh-85px)] mb-8 px-6 bg-white pb-6 pt-4 rounded-b-[10px] w-full flex flex-col flex-nowrap overflow-auto xl:flex-wrap shadow-lg">
      <div className="w-full xl:w-1/2 ">
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Created
          </span>
          <PInput
            className="h-[42px] w-[413px] "
            wrapperClassNames="h-full flex justify-center"
            value="Thursday, 19 September 2018 17:41:35"
          />
        </div>
        <hr className=" border-gray-300 xl:w-[340px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Registered
          </span>
          <PInput
            className="h-[42px] w-[413px] "
            wrapperClassNames="h-full flex justify-center"
            value="Thursday, 19 September 2018 17:41:35"
          />
        </div>
        <hr className=" border-gray-300 xl:w-[340px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Name
          </span>
          <PInput
            className="h-[42px] w-[413px] "
            wrapperClassNames="h-full flex justify-center"
            value="Max"
          />
        </div>
        <hr className=" border-gray-300 xl:w-[340px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Surname
          </span>
          <PInput
            className="h-[42px] w-[413px] "
            wrapperClassNames="h-full flex justify-center"
            value="Smith"
          />
        </div>
        <hr className=" border-gray-300 xl:w-[340px] w-full" />
        <div className="h-[50px] flex flex-row items-center justify-between  w-full">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Gender
          </span>
          <div className="flex items-center space-x-4 w-[413px]">
            <label className="flex items-center space-x-2 cursor-pointer">
              <Radio checked={false} />
              <span className="text-gray-700">Male</span>
            </label>

            <label className="flex items-center space-x-2 cursor-pointer">
              <Radio checked />
              <span className="text-gray-700">Female</span>
            </label>
          </div>
        </div>
        <hr className=" border-gray-300 xl:w-[340px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Language
          </span>
          <BSelect
            className="w-[413px]"
            options={languagesOptions}
            placeholder="Language*"
            value={language}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setLanguage(e.target.value)
            }
          />
        </div>
        <hr className=" border-gray-300 xl:w-[340px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Birthday
          </span>
          <BDatePicker
            value={birthdayDate}
            placeholder="Birthday*"
            onChange={(newDate: DateValueType | null) =>
              setBirthdayDate(newDate)
            }
            className="h-[50px] w-[413px] justify-center"
          />
        </div>
        <hr className=" border-gray-300 xl:w-[340px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Address Line 1
          </span>
          <PInput
            className="h-[42px] w-[413px]"
            wrapperClassNames="h-full flex justify-center"
            placeholder="Adress Line 1"
          />
        </div>
        <hr className=" border-gray-300 xl:w-[340px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Address Line 2
          </span>
          <PInput
            className="h-[42px] w-[413px]"
            wrapperClassNames="h-full flex justify-center"
            placeholder="Adress Line 2"
          />
        </div>
        <hr className=" border-gray-300 xl:w-[340px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Post Code
          </span>
          <PInput
            className="h-[42px] w-[413px]"
            wrapperClassNames="h-full flex justify-center"
            placeholder="Post Code"
          />
        </div>
        <hr className=" border-gray-300 xl:w-[340px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Country
          </span>
          <BSelect
            className="w-[413px]"
            options={countriesOptions || []}
            value={checkedCountry}
            placeholder="Country*"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setCheckedCountry(e.target.value)
            }
          />
        </div>
        <hr className=" border-gray-300 xl:w-[330px] w-full" />
      </div>
      <div className="w-full xl:w-1/2 xl:pl-6">
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Landline
          </span>
          <div className="h-full w-[413px] flex flex-row items-center ">
            <PInput
              className="h-[42px] w-[413px]"
              wrapperClassNames="h-full flex justify-center"
            />
          </div>
        </div>
        <hr className=" border-gray-300 xl:w-[320px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Affiliate
          </span>
          <div className="flex flex-row justify-start w-[413px]">
            <a
              href=""
              className="underline text-body-reg-14 font-semibold text-secondary"
            >
              Pixup Play Ltd.
            </a>
          </div>
        </div>
        <hr className=" border-gray-300 xl:w-[320px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Brand
          </span>
          <PInput
            className="h-[42px] w-[413px]"
            wrapperClassNames="h-full flex justify-center"
            placeholder="Lorem Ipsum Integration"
          />
        </div>
        <hr className=" border-gray-300 xl:w-[320px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Marketing Code
          </span>
          <BSelect
            className="w-[413px]"
            options={marketingCodesOptions || []}
            value={checkedMarketingCode}
            placeholder="Marketing Code*"
            onChange={(e) => setMarketingCode(e.target.value)}
          />
        </div>
        <hr className=" border-gray-300 xl:w-[320px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Revenue Scheme
          </span>
          <BSelect
            className="w-[413px]"
            options={revenueSchemesOptions}
            placeholder="Revenue Scheme*"
            value={revenueScheme}
            onChange={(e) => setRevenueScheme(e.target.value)}
          />
        </div>
        <hr className=" border-gray-300 xl:w-[320px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Is Registered
          </span>
          <div className="h-full w-[413px] flex flex-row items-center justify-start">
            <CheckBox
              checked
              classNames="h-full flex flex-row items-center justify-start"
            />
          </div>
        </div>
        <hr className=" border-gray-300 xl:w-[320px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Is Validated
          </span>
          <div className="h-full w-[413px] flex flex-row items-center justify-start">
            <CheckBox
              checked
              classNames="h-full flex flex-row items-center justify-start"
            />
          </div>
        </div>
        <hr className=" border-gray-300 xl:w-[320px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Accepted Ts And Cs
          </span>
          <div className="h-full w-[413px] flex flex-row items-center justify-start">
            <CheckBox
              checked
              classNames="h-full flex flex-row items-center justify-start"
            />
          </div>
        </div>
        <hr className=" border-gray-300 xl:w-[320px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Exclude From Revenue Runs
          </span>
          <div className="h-full w-[413px] flex flex-row items-center justify-start">
            <CheckBox
              checked
              classNames="h-full flex flex-row items-center justify-start"
            />
          </div>
        </div>
        <hr className=" border-gray-300 xl:w-[320px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Sound Notification Enabled
          </span>
          <div className="h-full w-[413px] flex flex-row items-center justify-start">
            <CheckBox
              checked
              classNames="h-full flex flex-row items-center justify-start"
            />
          </div>
        </div>
        <hr className=" border-gray-300 xl:w-[320px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Encryption Key
          </span>
          <PInput
            className="h-[42px] w-[413px]"
            wrapperClassNames="h-full flex justify-center"
            value="EAE80F75"
          />
        </div>
        <hr className=" border-gray-300 xl:w-[320px] w-full" />
        <div className="h-10 flex justify-end mt-7">
          <button
            type="button"
            className="bg-gray-200 text-gray-800 px-4 py-2 rounded-lg font-semibold hover:bg-gray-300 w-[139px] text-body-reg-13 h-full"
          >
            Reset
          </button>
          <button className="bg-indigo-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-indigo-600 w-[139px] text-body-reg-13 h-full ml-3">
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
