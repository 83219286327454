import React, { useState } from "react";
import PInput from "@components/core-components/input";
import BSelect from "@components/core-components/select";

const WithDrawal: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState("EUR");
  const [balance, setBalance] = useState("");
  const [inputValue, setInputValue] = useState("€4.416,00");

  const options = [
    { value: "EUR", label: "EUR" },
    { value: "USD", label: "USD" },
    { value: "INR", label: "INR" },
    { value: "TRY", label: "TRY" },
  ];

  return (
    <form className="flex flex-col pt-6 pb-9 mb-52 shadow-[0px_2px_6px_rgba(0,0,0,0.1)] bg-white">
      <main className="flex flex-col items-start px-6 w-full text-slate-600 max-md:px-5 max-md:max-w-full">
        <div className="h-[42px] flex flex-row items-center  w-full max-w-[855px] justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[300px] w-full">
            Balance
          </span>
          <div className="h-full w-[437px] flex flex-row items-center">
            <PInput
              type="number"
              value={balance}
              onChange={(e) => setBalance(e.target.value)}
              className="h-full w-[250px] font-bold rounded-r-none"
              wrapperClassNames="h-full"
              placeholder="€5.000,00"
            />
            <div className="h-full w-[187px] font-bold rounded-l-none border-l-0 bg-gray-200 border rounded-r-md border-gray-400">
              <span className="h-full flex items-center justify-center text-gray-600 rounded-l-lg text-body-reg-12 font-normal">
                Max Withdrawal:{" "}
                <span className="font-semibold ml-1">€100.000,00 </span>
              </span>
            </div>
          </div>
        </div>
        <hr className="mt-1 border-gray-300 w-full max-w-[406px]" />
        <div className="h-[42px] flex flex-row items-center w-full max-w-[855px] justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[300px] w-full">
            Player Wallet
          </span>
          <BSelect
            className="h-full w-[437px]"
            options={options}
            value={selectedOption}
            placeholder="Select an Option"
            onChange={(e) => setSelectedOption(e.target.value)}
          />
        </div>
        <hr className="mt-1 mb-1 border-gray-300 w-full max-w-[406px]" />
        <div className="h-[42px] flex flex-row items-center  w-full max-w-[855px] justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[300px] w-full">
            Amount
          </span>
          <PInput
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="h-full w-[437px] font-bold"
            wrapperClassNames="h-full"
            placeholder="Enter amount"
          />
        </div>
        <hr className="mt-1 border-gray-300 w-full max-w-[406px]" />
        <footer className="mt-5 w-full max-w-[855px] h-[42px] flex flex-row justify-end">
          <button
            type="button"
            className="bg-indigo-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-indigo-600 w-[139px] text-body-reg-13 h-full ml-3"
          >
            Withdrawal
          </button>
        </footer>
      </main>
    </form>
  );
};

export default WithDrawal;
