import { ColDef } from "ag-grid-community";

const cellClass =
  "ellipsis-cell flex flex-row items-center flex-start font-normal text-body-reg-14 text-gray-900";

export const handleDelete = (params: any) => {
  const id = params.data?.id;
};

export const personalMessagesColDef: ColDef[] = [
  {
    headerName: "DATE",
    field: "date",
    autoHeight: true,
    filter: "agDateColumnFilter",
    floatingFilter: true,
    cellClass,
    width: 157,
  },
  {
    headerName: "FROM",
    field: "from",
    autoHeight: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cellClass,
    width: 180,
  },
  {
    headerName: "MESSAGE",
    field: "message",
    autoHeight: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cellClass,
    width: 600,
  },
  {
    headerName: "PENDING UNTIL",
    field: "pending",
    autoHeight: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cellClass,
    flex: 2,
  },
  {
    headerName: "DELETE",
    width: 108,
    autoHeight: true,
    filter: false,
    floatingFilter: false,
    pinned: "right", // Valid value
    sortable: false,
    cellClass: "",
    cellRenderer: (params: any) => (
      <button
        className="w-20 h-7 bg-danger-light flex flex-row justify-center items-center rounded-md text-danger font-medium text-body-reg-12 hover:bg-danger hover:text-white"
        onClick={() => handleDelete(params)}
      >
        Delete
      </button>
    ),
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
];

export const recievedMessagesColDef: ColDef[] = [
  {
    headerName: "DATE",
    field: "date",
    autoHeight: true,
    filter: "agDateColumnFilter",
    floatingFilter: true,
    cellClass,
    width: 157,
  },
  {
    headerName: "FROM",
    field: "from",
    autoHeight: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cellClass,
    width: 180,
  },
  {
    headerName: "MESSAGE",
    field: "message",
    autoHeight: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cellClass,
    width: 600,
  },
  {
    headerName: "SERVED ON",
    field: "served",
    autoHeight: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cellClass,
    flex: 2,
  },
  {
    headerName: "DELETE",
    width: 108,
    autoHeight: true,
    filter: false,
    floatingFilter: false,
    pinned: "right",
    sortable: false,
    cellClass: "",
    cellRenderer: (params: any) => (
      <button
        className="w-20 h-7 bg-danger-light flex flex-row justify-center items-center rounded-md text-danger font-medium text-body-reg-12 hover:bg-danger hover:text-white"
        onClick={() => handleDelete(params)}
      >
        Delete
      </button>
    ),
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
];

export const broadCastMessagesColDef: ColDef[] = [
  {
    headerName: "DATE",
    field: "date",
    autoHeight: true,
    filter: "agDateColumnFilter",
    floatingFilter: true,
    cellClass,
    width: 157,
  },
  {
    headerName: "MESSAGE",
    field: "message",
    autoHeight: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cellClass,
    width: 950,
  },
  {
    headerName: "PENDING UNTIL",
    field: "pending",
    autoHeight: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cellClass,
    flex: 2,
  },
];

export const sentMessagesColDef: ColDef[] = [
  {
    headerName: "DATE",
    field: "date",
    autoHeight: true,
    filter: "agDateColumnFilter",
    floatingFilter: true,
    cellClass,
    width: 157,
  },
  {
    headerName: "TO",
    field: "to",
    autoHeight: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cellClass,
    width: 157,
  },
  {
    headerName: "MESSAGE",
    field: "message",
    autoHeight: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cellClass,
    flex: 2,
  },
  {
    headerName: "DELIVERED",
    field: "delivered",
    autoHeight: true,
    filter: "agDateColumnFilter",
    floatingFilter: true,
    cellClass,
    pinned: "right",
    flex: 2,
  },
];

export const mockData: any = [
  {
    id: 174005,
    date: "Jul 17 2024, 17:42",
    to: "Jul 17 2024, 17:42",
    delivered: "Jul 17 2024, 17:42",
    message: "Lorem ipsum dolar sit amet",
    pending: "Lorem Ipsum",
    from: "Micheal Bean",
    served: "Lorem ipsum dolar sit amet",
  },
  {
    id: 174006,
    date: "Jul 17 2024, 17:42",
    to: "Jul 17 2024, 17:42",
    delivered: "Jul 17 2024, 17:42",
    message: "Lorem ipsum dolar sit amet",
    pending: "Lorem Ipsum",
    from: "Micheal Bean",
    served: "Lorem ipsum dolar sit amet",
  },
  {
    id: 174007,
    date: "Jul 17 2024, 17:42",
    to: "Jul 17 2024, 17:42",
    delivered: "Jul 17 2024, 17:42",
    message: "Lorem ipsum dolar sit amet",
    pending: "Lorem Ipsum",
    from: "Micheal Bean",
    served: "Lorem ipsum dolar sit amet",
  },
  {
    id: 174008,
    date: "Jul 17 2024, 17:42",
    to: "Jul 17 2024, 17:42",
    delivered: "Jul 17 2024, 17:42",
    message: "Lorem ipsum dolar sit amet",
    pending: "Lorem Ipsum",
    from: "Micheal Bean",
    served: "Lorem ipsum dolar sit amet",
  },
  {
    id: 174009,
    date: "Jul 17 2024, 17:42",
    to: "Jul 17 2024, 17:42",
    delivered: "Jul 17 2024, 17:42",
    message: "Lorem ipsum dolar sit amet",
    pending: "Lorem Ipsum",
    from: "Micheal Bean",
    served: "Lorem ipsum dolar sit amet",
  },
  {
    id: 174010,
    date: "Jul 17 2024, 17:42",
    to: "Jul 17 2024, 17:42",
    delivered: "Jul 17 2024, 17:42",
    message: "Lorem ipsum dolar sit amet",
    pending: "Lorem Ipsum",
    from: "Micheal Bean",
    served: "Lorem ipsum dolar sit amet",
  },
];
