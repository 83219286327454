import { ChangeEvent, useCallback, useRef, useState } from "react";
import QuickFilterHeader from "./components/QuickFilterHeader";
import GridFilter from "./components/GridFilter";
import { PDataGrid } from "@components/core-components/grid/PDataGrid";
import Icon from "@components/core-components/icon";
import { useBaseQuery } from "api/core/useBaseQuery";
import "ag-grid-enterprise";
import DetailCellRenderer from "./components/DetailCellRenderer";
import { AgGridReact } from "ag-grid-react";
import { PLAYERS_API_URLS } from "config/apiUrls";
import dayjs from "dayjs";
function List() {
  const [filterParams, setFilterParams] = useState<any>({});
  const [closeFilters, setCloseFilters] = useState(true);
  const [disableFilters, setDisableFilters] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const [quickFilterText, setQuickFilterText] = useState("");
  const { data, isLoading: playersLoading } = useBaseQuery<[]>({
    endpoint: PLAYERS_API_URLS.GET_PLAYERS,
    enabled: Object.keys(filterParams).length === 0,
  });

  const filterParamsToString = (params: any) => {
    const filteredParams = Object.keys(params).reduce(
      (acc: any, key: string) => {
        const value = params[key];
        if (typeof value === "object" && value !== null && value.id) {
          acc[key] = value.id;
        } else {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
    const query = Object.keys(filteredParams)
      .map((key) => `${key}=${encodeURIComponent(filteredParams[key])}`)
      .join("&");

    return query ? `?${query}` : "";
  };
  const handleExportCSV = () => {
    if (gridRef.current) {
      const allColumns = gridRef.current.api.getColumns();
      const columnsToExport = allColumns?.filter(
        (col) => col.getColDef().field !== "quickProcess"
      );
      gridRef.current.api.exportDataAsCsv({
        columnKeys: columnsToExport?.map((col) => col.getColId()),
      });
    }
  };

  const { data: playerData, isLoading: filterLoading } = useBaseQuery<[]>({
    endpoint: `/pixupplay/filter${filterParamsToString(filterParams)}`,
    enabled: Object.keys(filterParams).length > 0,
  });
  const customStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
  const handleApplyFilters = (filters: any) => {
    setFilterParams(filters);
  };
  interface IRow {
    make: string;
    model: string;
    price: number;
    electric: boolean;
  }

  const gridRef = useRef<AgGridReact>(null);

  const onFilterTextBoxChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      gridRef.current!.api.setGridOption(
        "quickFilterText",
        (document.getElementById("filter-text-box") as HTMLInputElement).value
      );
      setQuickFilterText(e.target.value);
    },
    []
  );

  const [colDefs, setColDefs] = useState<any>([
    {
      headerName: "Players ID",
      field: "id",
      autoHeight: true,
      width: 120,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellClass: "ellipsis-cell",
      cellStyle: {
        ...customStyles,
        color: "var(--dark, #282A42)",
      },
    },
    {
      headerName: "Username",

      width: 130,
      autoHeight: true,
      field: "username",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cellClass: " text-primary",
      cellStyle: {
        ...customStyles,
        color: "var(--Primary-Primary, #666CFF)",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "normal",
        textUnderlineOffset: "2px",

        textDecoration: "underline",
      },
    },
    {
      headerName: "Affiliate ID",
      field: "affiliateID",
      filter: "agTextColumnFilter",
      suppressHeaderMenuButton: true,
      width: 130,
      autoHeight: true,
      headerClass: "header-ellipsis",
      floatingFilter: true,
      cellClass: "ellipsis-cell",
      cellStyle: {
        ...customStyles,
        color: "var(--dark, #282A42)",
      },
    },
    {
      headerName: "Email",
      field: "email",
      filter: "agTextColumnFilter",
      width: 250,
      autoHeight: true,
      floatingFilter: true,
      cellStyle: {
        ...customStyles,
        color: "var(--dark, #282A42)",
      },
    },
    {
      headerName: "Verified",
      field: "isValidated",
      width: 110,
      autoHeight: true,
      filter: true,
      floatingFilter: true,
      cellClass: "ellipsis-cell",
      cellStyle: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      valueFormatter: (params: any) => {
        return params.value ? "Verified" : "Not Verified";
      },
      filterParams: {
        valueFormatter: (params: any) => {
          return params.value ? "Verified" : "Not Verified";
        },
      },
      cellRenderer: (params: any) => {
        return (
          <span>
            {params.value ? (
              <Icon className="pr-2" iconName="verified" />
            ) : (
              <Icon iconName="cancel" />
            )}
          </span>
        );
      },
    },
    {
      headerName: "Currency",
      field: "ledger",
      filter: "agMultiColumnFilter",
      autoHeight: true,
      width: 150,
      floatingFilter: true,
      cellStyle: {
        ...customStyles,
        fontWeight: "bold",
      },
      valueGetter: (params: any) => {
        const ledger = Array.isArray(params.data.ledger)
          ? params.data.ledger
          : [];

        const filterModel = params.api.getFilterModel();
        const selectedCurrencies =
          filterModel?.ledger?.filterModels[1]?.values ||
          filterModel?.ledger?.filterModels[0]?.filter;

        const inputElement = document.querySelector(
          'input[aria-label="Balance Filter Input"]'
        ) as HTMLInputElement;

        if (selectedCurrencies && selectedCurrencies.length > 1) {
          if (inputElement !== null) {
            inputElement.value = "";
            inputElement.disabled = true;

            params.api.setColumnFilterModel("balance", null);

            setDisableFilters(true);
          }
        } else {
          if (inputElement !== null) {
            inputElement.disabled = false;
          }
        }

        const currencies = ledger
          .map((item: any) => item.currency)
          .join(", ")
          .split(", ")
          .map((curr: string) => curr.trim())
          .filter(
            (value: string, index: number, self: string[]) =>
              self.indexOf(value) === index
          );

        return currencies;
      },
      filterParams: {
        values: (params: any) => {
          const allCurrencies: string[] = [];
          params.api.forEachNode((node: any) => {
            const ledger = Array.isArray(node.data.ledger)
              ? node.data.ledger
              : [];
            ledger.forEach((item: { currency: string }) => {
              allCurrencies.push(item.currency);
            });
          });

          const uniqueCurrencies = Array.from(new Set(allCurrencies));

          params.success(uniqueCurrencies);
        },
        textFormatter: (value: string) => {
          return value.toLowerCase();
        },
      },
      cellRenderer: (params: any) => {
        const ledger = Array.isArray(params.data.ledger)
          ? params.data.ledger
          : [];
        const showIcon = ledger.length === 2 || ledger.length >= 3;
        const iconName = ledger.length === 2 ? "twoChoice" : "currency";

        return (
          <div className="flex flex-row gap-1 justify-center items-center">
            {showIcon && (
              <Icon
                iconName={iconName}
                svgProps={ledger.length >= 3 ? { width: 54, height: 68 } : {}}
                className="h-5 w-5 text-primary"
              />
            )}
            <div className={`flex flex-col gap-2 ${!showIcon ? " ml-6" : ""}`}>
              {ledger.map((item: any, index: number) => (
                <div key={index} className="flex flex-col items-start gap-2">
                  <span className={`inline-block text-sm font-medium `}>
                    {item.currency} ({item.symbol})
                  </span>
                </div>
              ))}
            </div>
          </div>
        );
      },
    },

    {
      headerName: "Balance",
      field: "balance",
      headerTooltip: "Only one currency can be filtered at a time",
      sortable: true,
      tooltipHeader: "Can filter only one currency at a time",
      width: 150,
      filter: "agNumberColumnFilter",
      autoHeight: true,
      floatingFilter: true,
      cellStyle: {
        ...customStyles,
        fontWeight: "bold",
      },
      valueGetter: (params: any) => {
        const ledger = Array.isArray(params.data.ledger)
          ? params.data.ledger
          : [];

        const filterModel = params.api.getFilterModel();
        const selectedCurrency =
          filterModel?.ledger?.filterModels?.[1]?.values?.[0] ||
          filterModel?.ledger?.filterModels?.[0]?.filter;

        if (selectedCurrency) {
          const selectedLedger = ledger.find((item: any) =>
            item.currency.includes(selectedCurrency.toLocaleUpperCase())
          );
          return selectedLedger ? selectedLedger.total : null;
        } else {
          const totalBalance = ledger.reduce(
            (acc: number, item: any) => acc + item.total,
            0
          );
          return totalBalance || null;
        }
      },

      comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any) => {
        return valueA - valueB;
      },
      filterParams: {
        defaultOption: "greaterThanOrEqual",
        numberParser: (text: string) => {
          return parseFloat(text?.replace(",", "."));
        },
      },
      cellRenderer: (params: any) => {
        const ledger = Array.isArray(params.data.ledger)
          ? params.data.ledger
          : [];

        const formatNumber = (value: number) => {
          return value?.toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        };

        if (ledger.length === 1) {
          const { total, symbol, currency } = ledger[0];
          return (
            <div className="flex flex-row gap-2">
              <span className={`inline-block  ml-6 text-sm font-medium`}>
                {formatNumber(total)} {symbol}
              </span>
            </div>
          );
        } else if (ledger.length === 2) {
          return (
            <div className="flex my-2 flex-row gap-1 items-center">
              <Icon iconName="twoChoice" className="h-5 w-5 text-primary" />
              <div className="flex flex-col gap-2">
                {ledger.map((item: any, index: number) => {
                  const { total, symbol, currency } = item;
                  return (
                    <span
                      key={index}
                      className={`inline-block text-sm font-medium`}
                    >
                      {formatNumber(total)} {symbol}
                    </span>
                  );
                })}
              </div>
            </div>
          );
        } else if (ledger.length >= 3) {
          return (
            <div className="flex my-2 flex-column gap-1 items-center">
              <Icon
                iconName={"currency"}
                svgProps={{ width: 54, height: 68 }}
                className="h-5 w-5 text-primary"
              />
              <div className="flex flex-col gap-2">
                {ledger.map((item: any, index: number) => {
                  const { total, symbol, currency } = item;
                  return (
                    <div
                      key={index}
                      className="flex flex-row items-center gap-1"
                    >
                      <span className={`inline-block text-sm font-medium`}>
                        {formatNumber(total)} {symbol}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }

        return <span>No data available</span>;
      },
    },
    {
      sortable: true,
      headerName: "Balance/EUR (Approximation)",

      field: "ledger",
      width: 150,
      autoHeight: true,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      cellStyle: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        color: "var(--dark, #282A42)",
        fontWeight: "bold",
      },

      valueGetter: (params: any) => {
        const ledger = Array.isArray(params.data.ledger)
          ? params.data.ledger
          : [];
        return ledger.length ? ledger[0].total : null;
      },

      filterParams: {
        defaultOption: "greaterThanOrEqual",
        numberParser: (text: string) => parseFloat(text?.replace(",", ".")),
      },

      valueFormatter: (params: any) => {
        const ledger = Array.isArray(params.data.ledger)
          ? params.data.ledger
          : [];
        const total = ledger.length ? ledger[0].total : 0;
        const symbol = ledger.length ? ledger[0].symbol : "€";

        return `${symbol} ${total.toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      },
    },

    {
      headerName: "Winning Tendency",
      field: "winningTendency",
      filter: true,
      width: 150,

      autoHeight: true,

      floatingFilter: true,
      cellClass: "ellipsis-cell",
      cellStyle: {
        ...customStyles,
      },
      cellRenderer: (params: any) => (
        <p
          style={{
            height: "28px",
          }}
          className=" flex items-center bg-success-light border border-success px-2 text-success rounded"
        >
          {" "}
          Low Tendency
        </p>
      ),
    },
    {
      headerName: "Last Login Date",
      field: "lastLoginDate",
      filter: "agDateColumnFilter",
      floatingFilter: true,
      autoHeight: true,
      width: 150,
      cellClass: "ellipsis-cell",
      cellStyle: {
        color: "var(--dark, #282A42)",
        fontWeight: "400",
        ...customStyles,
      },
      valueGetter: (params: any) => {
        return dayjs().format("DD.MM.YYYY");
      },
      cellRenderer: (params: any) => {
        return dayjs().format("DD.MM.YYYY");
      },
    },
    {
      headerName: "Last Login IP Address",
      field: "lastLoginIpAddress",
      filter: "agTextColumnFilter",
      floatingFilter: true,

      width: 150,
      autoHeight: true,
      cellStyle: {
        color: "var(--dark, #282A42)",
        ...customStyles,
      },
      valueGetter: (params: any) => {
        return "1.1.1.1.1.1";
      },
      cellRenderer: (params: any) => <span>1.1.1.1.1.1</span>,
    },
    {
      headerName: "Quick Process",
      autoHeight: true,
      IsRowMaster: false,
      detail: false,
      field: "quickProcess",
      minWidth: 100,
      width: 150,
      pinned: "right",
      cellStyle: {
        display: "flex",
        marginX: "0",
        alignItems: "center",
        gap: "4px",
        justifyContent: "center",
        padding: "0",
      },
      cellRenderer: (params: any) => {
        const handleAddQuick = () => {};
        const handleView = () => {
          if (params.node.expanded) {
            params.node.setExpanded(false);
          } else {
            params.node.setExpanded(true);
          }
        };

        return (
          <>
            <div className="flex flex-wrap align-center justify-center p-1 gap-1 ">
              <button
                onClick={handleAddQuick}
                className="flex items-center justify-center px-[14px] text-black transition"
                style={{
                  color: "var(--Gray-Gray-600, #78829D)",
                  borderRadius: "var(--Spacing-4, 4px)",
                  border: "1px dashed var(--Gray-Gray-600, #78829D)",
                  background: "var(--Base-Main-White, #FFF)",
                  gap: "8px",
                  height: "28px",
                }}
              >
                Add
              </button>
              <button
                onClick={handleView}
                style={{
                  height: 28,
                  width: "58px",
                  fontSize: "12px",
                }}
                className={`flex items-center bg-primary-light text-primary px-2 py-2 rounded-md transition-colors focus:outline-none focus:none space-x-1 hover:opacity-80 font-semibold text-sm`}
              >
                <span>View</span>
                <Icon className="h-2 w-3 pt-5 m-0" iconName="purpleArrow" />
              </button>
            </div>
          </>
        );
      },
    },
  ]);
  const onGridReady = useCallback((params: any) => {
    params.api.sizeColumnsToFit();
    const gridElement = document.getElementsByClassName(
      "ag-root"
    )[0] as HTMLElement;
    if (gridElement) {
      gridElement.style.minHeight = "400px";
    }
  }, []);

  return (
    <div className="bg-white h-full overflow-auto flex flex-col my-4 p-6">
      <QuickFilterHeader
        handleExportCSV={handleExportCSV}
        handleSearch={onFilterTextBoxChanged}
        searchText={quickFilterText}
        closeFilters={closeFilters}
        setCloseFilters={setCloseFilters}
      />

      <div className="flex flex-col flex-grow ">
        {" "}
        <div className="flex flex-col flex-grow z-[9]">
          <GridFilter
            filterParams={filterParams}
            setShowSimpleFilters={setCloseFilters}
            onApplyFilters={handleApplyFilters}
            showSimpleFilters={closeFilters}
          />
        </div>
        <div className="flex-grow h-screen ">
          {disableFilters && showAlert && (
            <div
              className="bg-blue-100 my-2 border border-blue-400 text-blue-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span className="block sm:inline">
                You can only filter by one currency at a time.
              </span>
              <button
                className="absolute top-0 right-0 px-4 py-3"
                onClick={() => {
                  setDisableFilters(false);
                  setShowAlert(false);
                }}
              >
                <svg
                  className="fill-current h-6 w-6 text-blue-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>Close</title>
                  <path d="M14.348 5.652a1 1 0 00-1.414 0L10 8.586 7.066 5.652a1 1 0 10-1.414 1.414L8.586 10l-2.934 2.934a1 1 0 101.414 1.414L10 11.414l2.934 2.934a1 1 0 001.414-1.414L11.414 10l2.934-2.934a1 1 0 000-1.414z" />
                </svg>
              </button>
            </div>
          )}

          <PDataGrid
            loading={playersLoading || filterLoading}
            gridRef={gridRef}
            DetailCellRenderer={DetailCellRenderer}
            colDefs={colDefs}
            rowData={
              Object.keys(filterParams).length > 0
                ? playerData || []
                : data || []
            }
            onGridReady={onGridReady}
          />
        </div>
      </div>
    </div>
  );
}

export default List;
