import { ColDef } from "ag-grid-community";
const cellClass =
  "ellipsis-cell flex flex-row items-center flex-start font-normal text-body-reg-14 text-gray-900";

export const emailsColDef: ColDef[] = [
  {
    headerName: "DATE",
    field: "date",
    autoHeight: true,
    filter: true,
    floatingFilter: true,
    cellClass,
    width: 150,
  },
  {
    headerName: "SUBJECT",
    field: "subject",
    autoHeight: true,
    filter: true,
    floatingFilter: true,
    cellClass,
    width: 850,
  },
  {
    headerName: "STATUS",
    field: "message",
    autoHeight: true,
    filter: true,
    floatingFilter: true,
    cellClass,
    width: 150,
  },
];

export const mockData: any = [
  {
    id: 174005,
    date: "Jul 17 2024, 17:42",
    subject: "Lorem ipsum dolar sit amet",
    status: "Lorem Ipsum",
  },
  {
    id: 174006,
    date: "Jul 17 2024, 17:42",
    subject: "Lorem ipsum dolar sit amet",
    status: "Lorem Ipsum",
  },
  {
    id: 174007,
    date: "Jul 17 2024, 17:42",
    subject: "Lorem ipsum dolar sit amet",
    status: "Lorem Ipsum",
  },
  {
    id: 174008,
    date: "Jul 17 2024, 17:42",
    subject: "Lorem ipsum dolar sit amet",
    status: "Lorem Ipsum",
  },
];
