import React, { Fragment, useState } from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import cx from "classnames";
import Icon from "@components/core-components/icon";
import BettingLimits from "./components/bettingLimit";

const Settings: React.FC = () => {
  return (
    <TabGroup>
      <TabList className="pl-[30px] bg-primary h-11 flex flex-row items-end">
        <Tab as={Fragment}>
          {({ hover, selected }) => (
            <button
              className={cx(
                "w-[117px] h-9 text-body-reg-13 no-underline font-medium flex flex-row items-center justify-center",
                selected &&
                  "focus:outline-none focus:ring-0 bg-white rounded-t-[8px] text-gray-900",
                !selected && "text-white"
              )}
            >
              Betting Limit
              <Icon
                iconName="downArrow"
                className={cx(
                  selected && "text-gray-900",
                  !selected && "text-white"
                )}
              />
            </button>
          )}
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <BettingLimits />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
};

export default Settings;
