import Checkbox from "@components/core-components/checkbox";
import BDatePicker from "@components/core-components/datePicker";
import PInput from "@components/core-components/input";
import BSelect from "@components/core-components/select";
import React, { useState } from "react";
import { DateValueType } from "react-tailwindcss-datepicker";

function PlayingLimits() {
  const [selectedOption, setSelectedOption] = React.useState("");
  const [defaultBetAmount, setDefaultBetAmount] = React.useState("0.5");
  const [maxBetAmount, setMaxBetAmount] = React.useState("1,000.00");
  const [dailyBetLimit, setDailyBetLimit] = React.useState("2,000.00");
  const [weeklyBetLimit, setWeeklyBetLimit] = React.useState("10,000.00");
  const [monthlyBetLimit, setMonthlyBetLimit] = React.useState("10,000.00");
  const [dailyDepositLimit, setDailyDepositLimit] = React.useState("2,000.00");
  const [weeklyDepositLimit, setWeeklyDepositLimit] =
    React.useState("10,000.00");
  const [monthlyDepositLimit, setMonthlyDepositLimit] =
    React.useState("10,000.00");
  const [untilDate, setUntilDate] = useState<DateValueType | null>(null);
  const [profitShutdownAmount, setProfitShutdownAmount] =
    React.useState("5,000.00");
  const [override, setOverride] = React.useState("");
  const [maxSessionLength, setMaxSessionLength] = React.useState("0");
  const [warnSessionLength, setWarnSessionLength] = React.useState("0");

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
  ];

  return (
    <div className="flex flex-row w-full pb-9 mb-52 bg-white justify-between shadow-[0px_6px_6px_rgba(0,0,0,0.1)]">
      <form className="flex flex-col ml-6">
        <div className="h-[42px] flex flex-col items-start w-full max-w-[855px] mb-6">
          <BSelect
            className="h-full w-[437px]"
            options={options}
            value={selectedOption}
            placeholder="Select an Option"
            onChange={(e) => setSelectedOption(e.target.value)}
          />
        </div>
        <main className="flex flex-col items-start w-full text-slate-600 max-md:max-w-full">
          <hr className="mt-1 mb-1 border-gray-300 w-full max-w-[308px]" />

          <div className="h-[42px] flex flex-row items-center w-full max-w-[552px] gap-6">
            <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[292px] min-w-[292px] w-full">
              Default Bet Amount
            </span>
            <PInput
              type="text"
              value={defaultBetAmount}
              onChange={(e) => setDefaultBetAmount(e.target.value)}
              className="h-full w-[236px] font-semibold"
              wrapperClassNames="h-full"
              placeholder="Enter amount"
              rightText="€"
            />
          </div>
          <hr className="mt-1 mb-1 border-gray-300 w-full max-w-[308px]" />
          <div className="h-[42px] flex flex-row items-center w-full max-w-[552px] gap-6">
            <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[292px] min-w-[292px] w-full">
              Maximum Bet Amount
            </span>
            <PInput
              type="text"
              value={maxBetAmount}
              onChange={(e) => setMaxBetAmount(e.target.value)}
              className="h-full w-[236px] font-semibold"
              wrapperClassNames="h-full"
              placeholder="Enter amount"
              rightText="€"
            />
          </div>
          <hr className="mt-1 mb-1 border-gray-300 w-full max-w-[308px]" />
          <div className="h-[42px] flex flex-row items-center w-full max-w-[552px] gap-6">
            <div className="flex flex-col items-center w-full gap-1">
              <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[292px] min-w-[292px] w-full">
                Daily Bet Limit
              </span>
              <span className="text-body-reg-14 font-semibold text-gray-700 leading-none max-w-[292px] w-full">
                Used: €4.374.00
              </span>
            </div>
            <PInput
              type="text"
              value={dailyBetLimit}
              onChange={(e) => setDailyBetLimit(e.target.value)}
              className="h-full w-[236px] font-semibold"
              wrapperClassNames="h-full"
              placeholder="Enter amount"
              rightText="€"
            />
          </div>
          <hr className="mt-1 mb-1 border-gray-300 w-full max-w-[308px]" />
          <div className="h-[42px] flex flex-row items-center w-full max-w-[552px] gap-6">
            <div className="flex flex-col items-center w-full gap-1">
              <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[292px] min-w-[292px] w-full">
                Weekly Bet Limit
              </span>
              <span className="text-body-reg-14 font-semibold text-gray-700 leading-none max-w-[292px] min-w-[292px] w-full">
                Used: €0.00
              </span>
            </div>
            <PInput
              type="text"
              value={weeklyBetLimit}
              onChange={(e) => setWeeklyBetLimit(e.target.value)}
              className="h-full w-[236px] font-semibold"
              wrapperClassNames="h-full"
              placeholder="Enter amount"
              rightText="€"
            />
          </div>
          <hr className="mt-1 mb-1 border-gray-300 w-full max-w-[308px]" />
          <div className="h-[42px] flex flex-row items-center w-full max-w-[552px] gap-6">
            <div className="flex flex-col items-center w-full gap-1">
              <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[292px] min-w-[292px] w-full">
                Monthly Bet Limit
              </span>
              <span className="text-body-reg-14 font-semibold text-gray-700 leading-none max-w-[292px] min-w-[292px] w-full">
                Used: €0.00
              </span>
            </div>
            <PInput
              type="text"
              value={monthlyBetLimit}
              onChange={(e) => setMonthlyBetLimit(e.target.value)}
              className="h-full w-[236px] font-semibold"
              wrapperClassNames="h-full"
              placeholder="Enter amount"
              rightText="€"
            />
          </div>
          <hr className="mt-1 mb-1 border-gray-300 w-full max-w-[308px]" />
          <div className="h-[42px] flex flex-row items-center w-full max-w-[552px] gap-6">
            <div className="flex flex-col items-center w-full gap-1">
              <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[292px] min-w-[292px] w-full">
                Daily Deposit Limit
              </span>
              <span className="text-body-reg-14 font-semibold text-gray-700 leading-none max-w-[292px] min-w-[292px] w-full">
                Used: €0.00
              </span>
            </div>
            <PInput
              type="text"
              value={dailyDepositLimit}
              onChange={(e) => setDailyDepositLimit(e.target.value)}
              className="h-full w-[236px] font-semibold"
              wrapperClassNames="h-full"
              placeholder="Enter amount"
              rightText="€"
            />
          </div>
          <hr className="mt-1 mb-1 border-gray-300 w-full max-w-[308px]" />
          <div className="h-[42px] flex flex-row items-center w-full max-w-[552px] gap-6">
            <div className="flex flex-col items-center w-full gap-1">
              <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[292px] min-w-[292px] w-full">
                Weekly Deposit Limit
              </span>
              <span className="text-body-reg-14 font-semibold text-gray-700 leading-none max-w-[292px] min-w-[292px] w-full">
                Used: €0.00
              </span>
            </div>
            <PInput
              type="text"
              value={weeklyDepositLimit}
              onChange={(e) => setWeeklyDepositLimit(e.target.value)}
              className="h-full w-[236px] font-semibold"
              wrapperClassNames="h-full"
              placeholder="Enter amount"
              rightText="€"
            />
          </div>
          <hr className="mt-1 mb-1 border-gray-300 w-full max-w-[308px]" />
          <div className="h-[42px] flex flex-row items-center w-full max-w-[552px] gap-6">
            <div className="flex flex-col items-center w-full gap-1">
              <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[292px] min-w-[292px] w-full">
                Monthly Deposit Limit
              </span>
              <span className="text-body-reg-14 font-semibold text-gray-700 leading-none max-w-[292px] min-w-[292px] w-full">
                Used: €0.00
              </span>
            </div>
            <PInput
              type="text"
              value={monthlyDepositLimit}
              onChange={(e) => setMonthlyDepositLimit(e.target.value)}
              className="h-full w-[236px] font-semibold"
              wrapperClassNames="h-full"
              placeholder="Enter amount"
              rightText="€"
            />
          </div>
          <hr className="mt-1 mb-1 border-gray-300 w-full max-w-[308px]" />
        </main>
      </form>
      <form className="flex flex-col pt-16 mr-6">
        <hr className="mt-1 mb-1 border-gray-300 w-full max-w-[308px]" />
        <main className="flex flex-col items-start w-full text-slate-600 max-md:max-w-full">
          <div className="h-[42px] flex flex-row items-center w-full max-w-[552px] gap-6">
            <div className="flex flex-col items-center w-full gap-1">
              <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[292px] min-w-[292px] w-full">
                Profit Shutdown Amount
              </span>
              <span className="text-body-reg-14 font-semibold text-gray-700 leading-none max-w-[292px] min-w-[292px] w-full">
                Used: €0.00
              </span>
            </div>
            <PInput
              type="text"
              value={profitShutdownAmount}
              onChange={(e) => setProfitShutdownAmount(e.target.value)}
              className="h-full w-[236px] font-semibold"
              wrapperClassNames="h-full"
              placeholder="Enter amount"
              rightText="€"
            />
          </div>
          <div className="h-[42px] mt-3 flex flex-row items-center w-full max-w-[552px] gap-6">
            <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[292px] min-w-[292px] w-full">
              Override: €
            </span>
            <PInput
              type="text"
              value={override}
              onChange={(e) => setOverride(e.target.value)}
              className="h-full w-[236px] font-semibold"
              wrapperClassNames="h-full"
              placeholder=""
            />
          </div>
          <div className="h-[42px] mt-3 flex flex-row items-center w-full max-w-[552px] gap-6">
            <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[292px] min-w-[292px] w-full">
              Until
            </span>
            <BDatePicker
              value={untilDate}
              placeholder="Date"
              onChange={(newDate: DateValueType | null) =>
                setUntilDate(newDate)
              }
              className="h-full min-w-[236px]"
            />
          </div>
          <hr className="mt-1 mb-1 border-gray-300 w-full max-w-[308px]" />
          <div className="h-[42px] flex flex-row items-center w-full max-w-[702px] gap-6">
            <div className="flex flex-col items-center w-full gap-1">
              <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[292px] min-w-[292px] w-full">
                Max Session Length
              </span>
            </div>
            <PInput
              type="text"
              value={maxSessionLength}
              onChange={(e) => setMaxSessionLength(e.target.value)}
              className="h-full w-[236px] font-semibold"
              wrapperClassNames="h-full"
              placeholder="Enter amount"
              rightText="€"
            />
            <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[126px] w-full">
              In Minutes
            </span>
          </div>
          <hr className="mt-1 mb-1 border-gray-300 w-full max-w-[308px]" />
          <div className="h-[42px] flex flex-row items-center w-full max-w-[702px] gap-6">
            <div className="flex flex-col items-center w-full gap-1">
              <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[292px] min-w-[292px] w-full">
                Warn Session Length
              </span>
            </div>
            <PInput
              type="text"
              value={warnSessionLength}
              onChange={(e) => setWarnSessionLength(e.target.value)}
              className="h-full w-[236px] font-semibold"
              wrapperClassNames="h-full"
              placeholder="Enter amount"
              rightText="€"
            />
            <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[126px] w-full">
              In Minutes
            </span>
          </div>
          <hr className="mt-1 mb-1 border-gray-300 w-full max-w-[308px]" />
          <div className="h-[42px] flex flex-row items-center w-full max-w-[340px] gap-6">
            <div className="flex flex-col items-center w-full gap-1">
              <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[292px] w-full">
                Allow Bonuses
              </span>
            </div>
            <Checkbox
              checked
              classNames="h-full flex flex-row items-center justify-start"
            />
          </div>
          <hr className="mt-1 mb-1 border-gray-300 w-full max-w-[308px]" />
        </main>
        <footer className="mt-36 w-full max-w-[855px] h-[42px] flex flex-row justify-end">
          <button
            type="button"
            className="bg-gray-200 text-gray-800 px-4 py-2 rounded-lg font-semibold hover:bg-gray-300 w-[139px] text-body-reg-13 h-full"
          >
            Reset
          </button>
          <button
            type="button"
            className="bg-indigo-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-indigo-600 w-[139px] text-body-reg-13 h-full ml-3"
          >
            Save
          </button>
        </footer>
      </form>
    </div>
  );
}

export default PlayingLimits;
