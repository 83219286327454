import { useState } from "react";
import ContentArea from "./components/ContentArea";
import Header from "./components/Header";
import { TabContext } from "./components/TabContext";

const player = {
  name: "Max Smith",
  id: "2814883",
  referralNumber: "48473829",
  location: "Serbia, Europe",
  balance: "€4.416,00",
  bonusWallet: "€500,00",
  profitability: "8.71%",
  grossGamingRevenue: "-€377,00",
  sportsBookAmount: "€1.740,00",
  casinoAmount: "€690,00",
  exchangeAmount: "€185,00",
};

function Details() {
  const [activeTab, setActiveTab] = useState("Overview");
  return (
    <div className="flex flex-col">
      <TabContext.Provider value={{ activeTab, setActiveTab }}>
        <Header {...player} />
        <ContentArea />
      </TabContext.Provider>
    </div>
  );
}

export default Details;
