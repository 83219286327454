import React, { ReactNode, useEffect, useRef } from "react";
import Icon from "@components/core-components/icon";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  content: ReactNode;
  footer: ReactNode;
  title: string;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  content,
  footer,
  title,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        className="flex flex-col rounded-xl shadow-lg min-w-[300px]"
        ref={modalRef}
      >
        <div className="flex flex-col justify-center rounded-t-xl h-[70px] px-8 w-full bg-primary text-white">
          <div className="h-[32px] flex flex-ro justify-between items-center">
            <div className="font-semibold text-heading-20">{title}</div>
            <Icon
              iconName="closeCircle"
              svgProps={{ height: 32, width: 32 }}
              onClick={onClose}
            />
          </div>
        </div>
        <hr className=" border-gray-300 w-full" />
        <div className="bg-white">{content}</div>
        <hr className=" border-gray-300 w-full" />
        <div className="flex justify-end w-full h-[80px] items-center pr-8 bg-white rounded-b-xl">
          {footer ? (
            footer
          ) : (
            <button
              className="bg-gray-200 text-gray-900 px-4 py-2 rounded-lg hover:bg-gray-300 text-body-reg-13 font-semibold w-[140px] h-[40px]"
              onClick={onClose}
            >
              Close
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
