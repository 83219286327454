import { Outlet } from "react-router-dom";
import { AppRouteProps } from "../../components/router/AppRoute";
import List from "./list";
import Details from "./details";

export const playersRoutes: AppRouteProps[] = [
  {
    path: "players",
    element: <Outlet />,
    children: [
      { path: "list", element: <List /> },
      { path: "edit", element: <Details /> },
    ],
  },
];
