import PInput from "@components/core-components/input";
import Header from "./Header";

export default function ChangePasswordTab() {
  return (
    <div className="h-[calc(100vh-110px)] xl:h-[424px] px-6 bg-white pb-6 rounded-b-[10px] w-full flex flex-col flex-nowrap overflow-auto shadow-lg">
      <div className="w-full xl:w-1/2 ">
        <Header title="Change Password" />
        <div className="flex flex-col h-[42px] justify-between mb-6">
          <p className="font-normal text-body-reg-13 text-warning">
            Use the form below to change your password
          </p>
          <p className="font-normal text-body-reg-13 text-warning">
            At least 1 letter, 1 number, 8-25 characters, symbols allowed
          </p>
        </div>
        <hr className=" border-gray-300 xl:w-[320px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Current Password
          </span>
          <PInput
            className="h-[42px] w-[437px] "
            wrapperClassNames="h-full flex justify-center"
            placeholder="Current Password"
            type="password"
          />
        </div>
        <hr className=" border-gray-300 xl:w-[320px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            New Password
          </span>
          <PInput
            className="h-[42px] w-[437px]"
            wrapperClassNames="h-full flex justify-center"
            placeholder="New Password"
            type="password"
          />
        </div>
        <hr className=" border-gray-300 w-full xl:w-[320px]" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Confirm Password
          </span>
          <PInput
            className="h-[42px] w-[437px]"
            wrapperClassNames="h-full flex justify-center"
            placeholder="Confirm Password"
            type="password"
          />
        </div>
        <hr className="border-gray-300 w-full xl:w-[320px] mb-3" />
        <div className="flex flex-row justify-end h-[50px] items-center">
          <button className="bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600 w-[139px] text-body-reg-13 h-[40px] justify-end">
            Change
          </button>
        </div>
      </div>
    </div>
  );
}
