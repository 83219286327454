import axiosInstance from "config/axiosInstance"


const authService = {
    login: async (userId: string, password: string) => {
        try {
            const data = {
            userid: userId,
            password: password,
            action: 'login.:'
            }

            const response = await axiosInstance.post('pxplogin', data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
            })

            return response
        } catch (error) {
            console.error('Error during login:', error)
            throw error
        }
    },
    checkAuthStatus: async () => {
        try {
            const response = await axiosInstance.get("/pixupplay/countries")
            return response.data;
        } catch (error) {
            console.error('Error during checkAuthStatus:', error);
            throw error;
        }
    },
    logout: async () => {
        try {
            const response = await axiosInstance.get('pxplogin?action=logout');
            console.log('logout response:', response);
            return response.data;
        } catch (error) {
            console.error('Error during logout:', error);
            throw error;
        }
    }
}

export default authService