import React, { ChangeEvent } from "react";
import { FieldError } from "react-hook-form";
import cx from "classnames";
import Icon from "../icon";

interface PInputProps {
  id?: string;
  type?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  className?: string;
  wrapperClassNames?: string;
  style?: React.CSSProperties;
  register?: any;
  error?: FieldError;
  label?: string;
  iconName?: string;
  showIcon?: boolean;
  rightText?: string;
  disabled?: boolean;
}

const PInput: React.FC<PInputProps> = ({
  id,
  type = "text",
  placeholder = "",
  value,
  onChange,
  required = false,
  className = "",
  wrapperClassNames = "",
  style,
  register,
  error,
  label,
  iconName,
  showIcon = false,
  rightText,
  disabled = false,
}) => {
  const inputClassNames = cx(
    `${className} border text-body-reg-13 bg-gray-100 border-gray-400 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-400 disabled:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-50`,
    {
      "border-2 border-red-500 bg-[var(--Danger-Danger-Light,#FFF1F2)]": error,
      "pl-9": showIcon,
      "pr-9": rightText,
    }
  );

  return (
    <div className={`${wrapperClassNames} flex flex-col relative`}>
      {label && (
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}

      {showIcon && iconName && (
        <div className="absolute left-4 top-1/2 transform -translate-y-1/2">
          <Icon
            svgProps={{
              width: "16px",
              height: "16px",
            }}
            iconName={iconName}
          />
        </div>
      )}

      <input
        disabled={disabled}
        style={style}
        type={type}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={inputClassNames}
        required={required}
        {...(register && register(id))}
      />

      {rightText && (
        <div className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-900 text-body-reg-14 font-semibold">
          {rightText}
        </div>
      )}

      {error && (
        <p className="mt-2 text-red-500 text-body-reg-12 font-medium">
          {error.message}
        </p>
      )}
    </div>
  );
};

export default PInput;
