import React from "react";
import PlayerInfo from "./PlayerInfo";
import FinancialOverview from "./FinancialOverview";
import NavigationTabs from "./NavigationTabs";

interface PlayerProfileProps {
  playerName: string;
  playerId: string;
  referralNumber: string;
  location: string;
  balance: string;
  bonusWallet: string;
  profitability: string;
  grossGamingRevenue: string;
  sportsBookAmount: string;
  casinoAmount: string;
  exchangeAmount: string;
}

export const PlayerProfile: React.FC<PlayerProfileProps> = ({
  playerName,
  playerId,
  referralNumber,
  location,
  balance,
  bonusWallet,
  profitability,
  grossGamingRevenue,
  sportsBookAmount,
  casinoAmount,
  exchangeAmount,
}) => {
  return (
    <main className="flex flex-col px-8 pt-8 pb-4 bg-white max-md:px-5">
      <div className="flex flex-col w-full max-md:max-w-full">
        <div className="flex flex-row justify-between items-start w-full max-md:max-w-full">
          <PlayerInfo
            playerName={playerName}
            playerId={playerId}
            referralNumber={referralNumber}
            location={location}
          />
          <FinancialOverview
            balance={balance}
            bonusWallet={bonusWallet}
            profitability={profitability}
            grossGamingRevenue={grossGamingRevenue}
            sportsBookAmount={sportsBookAmount}
            casinoAmount={casinoAmount}
            exchangeAmount={exchangeAmount}
          />
        </div>
        <NavigationTabs />
      </div>
    </main>
  );
};

export default PlayerProfile;
