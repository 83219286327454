import { Outlet } from "react-router-dom";
import { AppRouteProps } from "../../components/router/AppRoute";

import Bonuses from "./index";
import Bonus from ".";

export const bonusRoutes: AppRouteProps[] = [
  {
    path: "bonus",
    element: <Outlet />,
    children: [{ path: "list", element: <Bonus /> }],
  },
];
