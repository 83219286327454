interface HeaderProps {
  title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  return (
    <div className="w-full overflow-auto rounded-t-[10px] bg-white ">
      <div className="flex flex-row justify-between items-center pt-6 pb-4 ">
        <span className="text-heading-16 font-semibold">{title}</span>
      </div>
    </div>
  );
};

export default Header;
