import Checkbox from "@components/core-components/checkbox";
import PInput from "@components/core-components/input";
import { useAppSelector } from "hooks/redux";

export default function ChangePassword() {
  const { userId } = useAppSelector((state) => state.auth);
  return (
    <div className="h-[calc(100vh-110px)] xl:h-[calc(100vh-200px)] px-6 bg-white pb-6 pt-4 rounded-b-[10px] w-full flex flex-col flex-nowrap overflow-auto xl:flex-wrap ">
      <div className="w-full xl:w-1/2 ">
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Username
          </span>
          <PInput
            className="h-[42px] w-[413px]"
            wrapperClassNames="h-full flex justify-center"
            placeholder="Username"
            type="text"
            value={userId || "aliquantum99@gmail.com"}
          />
        </div>
        <hr className=" border-gray-300 xxl:w-[360px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Password
          </span>
          <PInput
            className="h-[42px] w-[413px]"
            wrapperClassNames="h-full flex justify-center"
            placeholder="Password"
            type="password"
            value=""
          />
        </div>
        <hr className=" border-gray-300 xxl:w-[360px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Force Password Change
          </span>
          <div className="w-[413px]">
            <Checkbox checked />
          </div>
        </div>
        <hr className=" border-gray-300 xxl:w-[360px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Email Address
          </span>
          <PInput
            className="h-[42px] w-[413px] "
            wrapperClassNames="h-full flex justify-center"
            placeholder="Email Address"
            type="email"
            value="aliquantum99@gmail.com"
          />
        </div>
        <hr className=" border-gray-300 xxl:w-[360px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Secret Question
          </span>
          <PInput
            className="h-[42px] w-[413px] "
            wrapperClassNames="h-full flex justify-center"
            placeholder="Secret Question"
            type="text"
          />
        </div>
        <hr className=" border-gray-300 xxl:w-[360px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Answer
          </span>
          <PInput
            className="h-[42px] w-[413px]"
            wrapperClassNames="h-full flex justify-center"
            placeholder="Answer"
            type="text"
          />
        </div>
        <hr className=" border-gray-300 xxl:w-[360px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Last Logged In
          </span>
          <PInput
            className="h-[42px] w-[413px]"
            wrapperClassNames="h-full flex justify-center"
            placeholder="Last Logged In"
            type="text"
            value="Thursday, 19 September 2018 17:41:35"
            disabled
          />
        </div>
        <hr className=" border-gray-300 xxl:w-[360px] w-full mb-3" />
        <div className="flex flex-row justify-end h-[50px] items-center gap-3">
          <button className="bg-gray-300 text-gray-900 px-4 py-2 rounded-lg hover:bg-gray-400 w-[140px] text-body-reg-13 h-[40px] font-semibold">
            Resend Activation
          </button>
          <button className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-indigo-600 w-[139px] text-body-reg-13 h-[40px] font-semibold">
            Resend Details
          </button>
        </div>
      </div>
    </div>
  );
}
