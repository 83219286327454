import React from "react";
import blueTick from "../../../../assets/images/blueTick.png";
import Player from "../../../../assets/images/Player-IDavatar.png";
import global from "../../../../assets/images/global.png";
import award from "../../../../assets/images/award.png";
import copy from "../../../../assets/images/copy.png";

interface PlayerInfoProps {
  playerName: string;
  playerId: string;
  referralNumber: string;
  location: string;
}

const PlayerInfo: React.FC<PlayerInfoProps> = ({
  playerName,
  playerId,
  referralNumber,
  location,
}) => {
  return (
    <section className="flex grow shrink gap-6 items-center min-w-[240px] max-w-[403px] w-full max-md:max-w-full">
      <div className="flex flex-col self-stretch my-auto w-[134px] text-6xl font-semibold text-center text-white whitespace-nowrap rounded-lg max-md:text-4xl">
        <div className="px-4 w-[134px] h-[134px] bg-[var(--Warning-Warning,#FF6E1D)] rounded-lg max-md:px-5 max-md:text-4xl justify-center content-center">
          {playerName
            .split(" ")
            .map((name) => name[0])
            .join("")}
        </div>
      </div>
      <div className="flex flex-col self-stretch my-auto w-[222px] gap-2">
        <div className="flex flex-col max-w-full w-full gap-2">
          <div className="flex gap-2 items-center w-full text-lg leading-[22px] font-semibold text-slate-800">
            <div className="self-stretch my-auto">{playerName}</div>
            <img
              loading="lazy"
              src={blueTick}
              className="object-contain shrink-0 self-stretch my-auto aspect-square w-[18px]"
              alt="blue-tick"
            />
          </div>
          <div className="self-start p-2 text-xs font-medium leading-none text-[#17C653] bg-emerald-50 border border-[#17C653] border-solid rounded">
            Low Tendency
          </div>
        </div>
        <div className="flex flex-col gap-2 items-start w-full text-sm font-medium leading-4 text-[#78829D]">
          <div className="flex gap-2 items-center">
            <img
              loading="lazy"
              src={Player}
              className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square"
              alt="player"
            />
            <div className="flex gap-1 items-center self-stretch my-auto">
              <div className="self-stretch my-auto">Player ID:</div>
              <div className="self-stretch my-auto">{playerId}</div>
            </div>
          </div>
          <div className="flex gap-2 items-center self-stretch w-full">
            <img
              loading="lazy"
              src={award}
              className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square"
              alt="referral-number"
            />
            <div className="flex gap-1 items-center self-stretch my-auto">
              <div className="self-stretch my-auto">Referral Number:</div>
              <div className="self-stretch my-auto">{referralNumber}</div>
            </div>
            <img
              loading="lazy"
              src={copy}
              className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square"
              alt="copy"
            />
          </div>
          <div className="flex gap-2 items-center">
            <img
              loading="lazy"
              src={global}
              className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square"
              alt="location"
            />
            <div className="gap-1 self-stretch my-auto">{location}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlayerInfo;
