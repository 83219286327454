import React, { useContext, useState } from "react";
import { TabContext } from "./TabContext";

const NavigationTabs: React.FC = () => {
  const [tabs, setTabs] = useState([
    "Overview",
    "Info",
    "Reports",
    "Payment",
    "Bonus",
    "Notes",
    "Settings",
  ]);
  const { activeTab, setActiveTab } = useContext(TabContext);

  return (
    <nav className="flex flex-wrap gap-1 items-center p-1 py-1.5 mt-3 w-full text-sm font-medium leading-none whitespace-nowrap bg-gray-50 rounded-lg min-h-[42px] text-slate-600 max-md:max-w-full">
      {tabs.map((tab, index) => (
        <button
          key={index}
          className={`flex-1 shrink gap-2.5 self-stretch px-3.5 py-2.5 my-auto rounded-md ${
            tab === activeTab
              ? "font-semibold text-white bg-indigo-500 shadow-sm min-h-[34px]"
              : ""
          }`}
          onClick={() => {
            setActiveTab(tab);
          }}
        >
          {tab}
        </button>
      ))}
    </nav>
  );
};

export default NavigationTabs;
