import PInput from "@components/core-components/input";
import BSelect from "@components/core-components/select";
import Header from "./Header";
import Checkbox from "@components/core-components/checkbox";

export default function ProfileDetailsTab() {
  return (
    <div className="h-[calc(100vh-110px)] xl:h-[730px]  px-6 bg-white pb-6 rounded-b-[10px] w-full flex flex-col flex-nowrap overflow-auto xl:flex-wrap shadow-lg">
      <div className="w-full xl:w-1/2 ">
        <Header title="Profile Details" />
        <hr className=" border-gray-300 xl:w-[340px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Name
          </span>
          <PInput
            className="h-[42px] w-[413px] "
            wrapperClassNames="h-full flex justify-center"
            value="Demo User"
          />
        </div>
        <hr className=" border-gray-300 xl:w-[340px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Email Address
          </span>
          <PInput
            className="h-[42px] w-[413px]"
            wrapperClassNames="h-full flex justify-center"
            value="demouser@gmail.com"
          />
        </div>
        <hr className=" border-gray-300 xl:w-[340px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Phone Number
          </span>
          <PInput
            className="h-[42px] w-[413px]"
            wrapperClassNames="h-full flex justify-center"
            value="+90 545 366 00 00"
          />
        </div>
        <hr className="border-gray-300 xl:w-[340px] w-full" />
        <div className="h-[50px] flex flex-row items-center w-full justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700">
            Admin Skin
          </span>
          <BSelect
            className="h-[42px] w-[413px]"
            value="Admin"
            placeholder="System Default"
            options={[
              {
                value: "Admin",
                label: "Admin",
              },
            ]}
            onChange={() => console.log("onChange Admin Skin")}
          />
        </div>
        <hr className=" border-gray-300 my-1 xl:w-[340px] w-full" />
      </div>
      <div className="xl:w-1/2">
        <Header title="Client Certificate" />
        <hr className=" border-gray-300 w-full" />
        <div className="flex flex-row justify-between h-[50px] items-center">
          <p className="text-gray-700 font-medium text-body-reg-14">
            Require Certificate for Login?
          </p>
          <Checkbox checked />
        </div>
        <hr className=" border-gray-300 w-full" />
        <div className="flex flex-row justify-between h-[50px] items-center">
          <p className="text-gray-700 font-medium text-body-reg-14">Generate</p>
          <a className="font-semibold text-body-reg-14 underline text-secondary">
            Generate Certificate
          </a>
        </div>
        <hr className=" border-gray-300" />
        <div className="flex flex-row justify-between h-[50px] items-center">
          <p className="text-gray-700 font-medium text-body-reg-14 leading-normal">
            Guide
          </p>
          <a className="font-semibold text-body-reg-14 underline text-secondary">
            Cerificate Guide
          </a>
        </div>
        <hr className=" border-gray-300" />
      </div>
      <div className="xl:w-1/2 xl:pl-6">
        <Header title="WebDav Access" />
        <hr className=" border-gray-300 w-full" />
        <div className="flex flex-row justify-between h-[50px] items-center">
          <p className="text-gray-700 font-medium text-body-reg-14">
            Report Path
          </p>
          <a
            className="font-semibold text-body-reg-14 underline text-secondary"
            href="https://integration.aliquantum-gaming.com/WebDav/data/t5/reports/demo.test/"
            target="_blank"
          >
            https://integration.aliquantum-gaming.com/WebDav/data/t5/reports/demo.test/
          </a>
        </div>
        <hr className=" border-gray-300 w-full" />
        <div className="flex flex-row justify-between h-[50px] items-center">
          <p className="text-gray-700 font-medium text-body-reg-14 leading-normal">
            Brand Files
          </p>
          <a
            className="font-semibold text-body-reg-14 underline text-secondary"
            href="https://integration.aliquantum-gaming.com/WebDav/web/t5/"
            target="_blank"
          >
            https://integration.aliquantum-gaming.com/WebDav/web/t5/
          </a>
        </div>
        <hr className="border-gray-300 mb-4" />
        <div className="flex flex-row justify-end h-[50px] items-center">
          <button className="bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600 w-[139px] text-body-reg-13 h-[40px] justify-end">
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
