import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { checkAuthStatus } from "store/auth/authenticationSlice";
import Router from "./pages/routes";
import Login from "pages/auth/login";

function App() {
  const dispatch = useAppDispatch();
  //@ts-ignore
  const { isAuthenticated, loading } = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(checkAuthStatus());
  }, [dispatch]);

  return <>{loading ? <></> : isAuthenticated ? <Router /> : <Login />}</>;
}

export default App;

//pipeline test
