import Subheader from "pages/players/details/components/Subheader";
import React, { useState } from "react";
import PlayingLimits from "./components/PlayingLimits";

function BettingLimits() {
  const [activeTab, setActiveTab] = useState("Playing Limits");

  const renderContent = () => {
    switch (activeTab) {
      case "Playing Limits":
        return <PlayingLimits />;
      default:
        return null;
    }
  };

  return (
    <>
      <Subheader
        tabs={["Playing Limits"]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {renderContent()}
    </>
  );
}

export default BettingLimits;
