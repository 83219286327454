import React from "react";
import Icon from "@components/core-components/icon";

type BreadCrumbProps = {
  pages: { name: string; href: string; current: boolean }[];
};

function BreadCrumb({ pages }: BreadCrumbProps) {
  return (
    <nav aria-label="Breadcrumb" className="flex">
      <ol className="flex items-center space-x-4">
        {pages.map((page, index) => (
          <li key={page.name}>
            <div className="flex items-center">
              {index > 0 && (
                <Icon
                  iconName="rightArrow"
                  className="h-2 w-2 flex-shrink-0 text-gray-400"
                  svgProps={{ width: 10, height: 10 }}
                />
              )}
              <a
                href={page.href}
                aria-current={page.current ? "page" : undefined}
                className={`ml-${
                  index > 0 ? "2" : "0"
                } text-sm font-medium capitalize ${
                  page.current
                    ? "text-gray-700"
                    : "text-gray-500 hover:text-gray-700"
                }`}
              >
                {page.name}
              </a>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default BreadCrumb;
