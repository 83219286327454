import React from "react";

interface FinancialBreakdownProps {
  sportsBookAmount: string;
  casinoAmount: string;
  exchangeAmount: string;
}

const FinancialBreakdown: React.FC<FinancialBreakdownProps> = ({
  sportsBookAmount,
  casinoAmount,
  exchangeAmount,
}) => {
  const breakdownData = [
    {
      color: "#17C653",
      label: "Sportsbook",
      percentage: "55%",
      amount: sportsBookAmount,
    },
    {
      color: "#FF6E1D",
      label: "Casino",
      percentage: "25%",
      amount: casinoAmount,
    },
    {
      color: "#666CFF",
      label: "Exchange",
      percentage: "20%",
      amount: exchangeAmount,
    },
  ];

  return (
    <div className="flex flex-col mt-3 w-full max-md:max-w-full">
      <div className="flex flex-wrap gap-1 items-center w-full max-md:max-w-full">
        <div className="flex flex-row gap-1 items-center w-full max-md:max-w-full">
          {breakdownData.map((data, index) => (
            <div
              key={index}
              className="flex gap-2.5 self-stretch my-auto h-2 rounded-sm min-h-[8px]"
              style={{
                backgroundColor: data.color,
                width: data.percentage,
              }}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-wrap gap-20 items-center self-start mt-4 text-sm font-medium leading-loose text-slate-600 w-full max-md:max-w-full">
        {breakdownData.map((data, index) => (
          <div
            key={index}
            className="flex gap-2 items-center self-stretch my-auto"
          >
            <div
              className={`flex shrink-0 self-stretch my-auto w-2 h-2 rounded-3xl`}
              style={{ backgroundColor: data.color }}
            />
            <div className="self-stretch my-auto">
              <span className="leading-4 text-slate-600">
                {data.label} ({data.percentage}) -{" "}
              </span>
              <span className="font-semibold leading-4 text-slate-600">
                {data.amount}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FinancialBreakdown;
