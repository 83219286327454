import { Outlet } from "react-router-dom";
import { AppRouteProps } from "../../components/router/AppRoute";
import ProfileDetails from "./details";

export const profileRoutes: AppRouteProps[] = [
  {
    path: "profile",
    element: <Outlet />,
    children: [{ path: "details", element: <ProfileDetails /> }],
  },
];
