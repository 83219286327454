import PInput from "@components/core-components/input";
import React from "react";

const InputField = ({
  placeholder,
  onChange,
  id,
  type = "text",
  value,
  className = "w-full text-body-reg-13 h-[42px] border-gray-300 rounded-lg p-2 ",
}: {
  placeholder: string;
  id: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  value: string;
  className?: string;
}) => (
  <div className="col-span-3 mr-2">
    <PInput
      placeholder={placeholder}
      id={id}
      value={value}
      onChange={onChange}
      type={type}
      className={className}
    />
  </div>
);

export default InputField;
