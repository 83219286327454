import React from 'react';

interface TabContextType {
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

export const TabContext = React.createContext<TabContextType>({
  activeTab: 'overview',
  setActiveTab: () => {
    
  },
});