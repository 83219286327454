import React from "react";
import cx from "classnames";

interface SubheaderProps {
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  tabs: string[];
}

const Subheader: React.FC<SubheaderProps> = ({
  activeTab,
  setActiveTab,
  tabs,
}) => {
  return (
    <div className="h-full w-full overflow-auto rounded-t-[10px] bg-white ">
      <div>
        <div className="h-[54px] flex flex-row gap-6 py-3 px-[30px] justify-start items-center">
          {tabs.map((tab: string) => (
            <button
              key={tab}
              type="button"
              onClick={() => setActiveTab(tab)}
              className={cx(
                "font-medium text-body-reg-13 rounded-lg hover:bg-gray-300 h-[30px] min-w-[27px] p-2",
                {
                  "text-gray-700 bg-gray-300": activeTab.includes(tab),
                  "text-gray-600 ": !activeTab.includes(tab),
                }
              )}
            >
              {tab}
            </button>
          ))}
        </div>
        <hr className="w-full border-gray-300 pb-6" />
      </div>
    </div>
  );
};

export default Subheader;
