import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import cx from "classnames";
import { Fragment } from "react";
import Messages from "./tabs/messages";
import PlayerInfo from "./tabs/player-info";
import PrivacyInfo from "./tabs/privacy-info";
import ChangePassword from "./tabs/change-password";
import Emails from "./tabs/emails";
import Icon from "@components/core-components/icon";

export default function Info() {
  return (
    <TabGroup>
      <TabList className="pl-[30px] bg-primary h-11 flex flex-row items-end">
        <Tab as={Fragment}>
          {({ hover, selected }) => (
            <button
              className={cx(
                "w-[117px] h-9  text-body-reg-13 no-underline font-medium",
                selected &&
                  "focus:outline-none focus:ring-0 bg-white rounded-t-[8px] text-gray-900",
                !selected && "text-white"
              )}
            >
              Player Info
            </button>
          )}
        </Tab>
        <Tab as={Fragment}>
          {({ hover, selected }) => (
            <button
              className={cx(
                "w-[142px] h-9  text-body-reg-13 no-underline font-medium",
                selected &&
                  "focus:outline-none focus:ring-0 bg-white rounded-t-[8px] text-gray-900",
                !selected && "text-white"
              )}
            >
              Change Password
            </button>
          )}
        </Tab>
        <Tab as={Fragment}>
          {({ hover, selected }) => (
            <button
              className={cx(
                "w-[117px] h-9  text-body-reg-13 no-underline font-medium",
                selected &&
                  "focus:outline-none focus:ring-0 bg-white rounded-t-[8px] text-gray-900",
                !selected && "text-white"
              )}
            >
              Privacy Info
            </button>
          )}
        </Tab>
        <Tab as={Fragment}>
          {({ hover, selected }) => (
            <button
              className={cx(
                "w-[117px] h-9 text-body-reg-13 no-underline font-medium flex flex-row items-center justify-center",
                selected &&
                  "focus:outline-none focus:ring-0 bg-white rounded-t-[8px] text-gray-900",
                !selected && "text-white"
              )}
            >
              Messages
              <Icon
                iconName="downArrow"
                className={cx(
                  selected && "text-gray-900",
                  !selected && "text-white"
                )}
              />
            </button>
          )}
        </Tab>
        <Tab as={Fragment}>
          {({ hover, selected }) => (
            <button
              className={cx(
                "w-[117px] h-9  text-body-reg-13 no-underline font-medium",
                selected &&
                  "focus:outline-none focus:ring-0 bg-white rounded-t-[8px] text-gray-900",
                !selected && "text-white"
              )}
            >
              Emails
            </button>
          )}
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <PlayerInfo />
        </TabPanel>
        <TabPanel>
          <ChangePassword />
        </TabPanel>
        <TabPanel>
          <PrivacyInfo />
        </TabPanel>
        <TabPanel>
          <Messages />
        </TabPanel>
        <TabPanel>
          <Emails />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
}
