import React from "react";

interface AddNoteButtonProps {
  onClick: () => void;
}

const AddNoteButton: React.FC<AddNoteButtonProps> = ({ onClick }) => {
  return (
    <div className="flex gap-3 items-center self-stretch my-auto text-sm font-bold leading-none text-center text-white w-[139px]">
      <button
        onClick={onClick}
        className="flex items-center justify-center gap-2.5 self-stretch px-6 py-4 my-auto h-10 bg-indigo-500 rounded-lg w-[139px] max-md:px-5 text-body-reg-13 font-bold hover:bg-indigo-600"
      >
        Add Note
      </button>
    </div>
  );
};

export default AddNoteButton;
