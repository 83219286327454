import logo from "../../../assets/images/logo-dark.png";
import PInput from "@components/core-components/input";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "hooks/redux";
import { authenticationLogin } from "store/auth/authenticationSlice";
import { useState } from "react";
import Icon from "@components/core-components/icon";
import { useSelector } from "react-redux";
import { RootState } from "store";

const schema = yup.object().shape({
  userId: yup.string().required("You entered an incorrect email"),
  password: yup.string().required("You entered an incorrect password"),
});

interface IFormInput {
  userId: string;
  password: string;
}

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const [forgotPasswordActive, setForgotPasswordActive] = useState(false);
  const dispatch = useAppDispatch();
  const authError = useSelector((state: RootState) => state.auth.error);
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    await dispatch(authenticationLogin(data));
  };

  const handleForgotPassClick = () =>
    setForgotPasswordActive(!forgotPasswordActive);

  return (
    <div className="flex justify-center h-screen">
      {forgotPasswordActive && (
        <div className="absolute top-[34px] left-[34px] h-[34px] flex flex-row gap-4 items-center justify-center">
          <Icon
            iconName="backIcon"
            svgProps={{ width: 34, height: 34 }}
            onClick={handleForgotPassClick}
          />
          <span className="text-heading-24 font-semibold text-gray-900">
            Back
          </span>
        </div>
      )}
      <div className="flex items-center flex-col md:w-[380px] mt-[170px] ">
        <div>
          <img src={logo} width={253} height={75} alt="logo" />
        </div>
        <div>
          <h1 className="text-heading-24 text-dark font-medium my-12">
            {!forgotPasswordActive
              ? "Sign in to your account"
              : "Forgot Password"}
          </h1>
        </div>
        {!forgotPasswordActive ? (
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <PInput
              placeholder="Email Address"
              id="userId"
              type="userId"
              register={register}
              error={errors.userId}
              className="w-full text-body-reg-13 h-[42px]"
            />
            <PInput
              placeholder="Password"
              id="password"
              type="password"
              register={register}
              error={errors.password}
              className="w-full text-body-reg-13 h-[42px] mt-3"
            />
            {!errors.password && !errors.userId && authError && (
              <p className="mt-2 text-red-500 text-body-reg-12 font-medium">
                {authError}
              </p>
            )}

            <div className="flex justify-end w-full">
              <button
                className="text-primary underline font-semibold text-body-reg-14 my-4"
                onClick={handleForgotPassClick}
              >
                Forgot password
              </button>
            </div>
            <button
              onClick={handleSubmit(onSubmit)}
              type="button"
              className="bg-primary text-white font-semibold rounded-md hover:bg-primary-dark transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-400 h-[45px] w-full text-body-reg-13"
            >
              SIGN IN
            </button>
          </form>
        ) : (
          <form className="w-full">
            <PInput
              placeholder="Email Address"
              id="password"
              type="email"
              className="w-full text-body-reg-13 h-[42px] mb-[18px]"
            />
            <button
              type="submit"
              className="bg-primary text-white font-semibold rounded-md hover:bg-primary-dark transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-400 h-[45px] w-full text-body-reg-13"
            >
              Send
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
