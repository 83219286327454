const API_BASE_URL = 'pixupplay/';


export const COUNTRY_API_URLS = {
    GET_COUNTRIES: `${API_BASE_URL}countries`,
};

export const MARKETİNG_CODES_API_URLS = {
    GET_MARKETING_CODES: `${API_BASE_URL}marketing-codes`
};

export const CURRENCY_API_URLS = {
    GET_CURRENCY: `${API_BASE_URL}currencies`
};

export const REVENUE_SCHEMES_API_URLS = {
    GET_REVENUE_SCHEMES: `${API_BASE_URL}revenue-schemes`
};

export const LANGUAGES_API_URLS = {
    GET_LANGUAGES: `${API_BASE_URL}languages`
};

export const PLAYERS_API_URLS = {
    GET_PLAYERS: `${API_BASE_URL}players`
};