import React from "react";

interface RadioProps {
  onChange?: () => void;
  checked: boolean;
  disabled?: boolean;
  name?: string;
}

const Radio: React.FC<RadioProps> = ({
  onChange,
  checked,
  name,
  disabled = false,
}) => {
  return (
    <>
      <input
        type="radio"
        name="custom-radio"
        className="peer hidden"
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <span
        className={`w-[26px] h-[26px] rounded-full border-2 border-none flex items-center justify-center 
          ${checked ? "bg-blue-500" : "bg-gray-400"}`}
      >
        {checked && <span className="w-3.5 h-3.5 rounded-full bg-white"></span>}
      </span>
    </>
  );
};

export default Radio;
