const Header: React.FC = () => {
  return (
    <div className="w-full overflow-auto px-6 py-6 rounded-t-[10px] bg-white ">
      <div className="h-[45px] flex flex-row justify-between items-center">
        <span className="text-heading-16 font-semibold">Emails</span>
      </div>
      <hr className="mt-3 border-gray-300 w-full" />
    </div>
  );
};

export default Header;
