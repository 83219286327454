import React from "react";

const FormLabel = ({
  label,
  border = true,
}: {
  label: string;
  border?: boolean;
}) => (
  <div
    style={{
      gap: "15px",
      alignItems: "center",
      justifyContent: border ? "center" : "center",
    }}
    className={`col-span-2 h-full  flex flex-col  ml-2 ${
      border ? "border-b border-gray-300 " : ""
    } `}
  >
    <label className="text-body-reg-14 text-gray-700 w-full">{label}</label>
  </div>
);
export default FormLabel;
