import React, { Fragment, useState } from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import cx from "classnames";
import Modal from "@components/core-components/modal";
import PInput from "@components/core-components/input";
import FilterButton from "./components/FilterButton";
import AddNoteButton from "./components/AddNoteButton";
import NotesTable from "./components/NotesTable";

interface Note {
  date: string;
  createdBy: string;
  content: string;
}

const NotesComponent: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <TabGroup>
      <TabList className="pl-[30px] bg-primary h-11 flex flex-row items-end">
        <Tab as={Fragment}>
          {({ selected }) => (
            <button
              className={cx(
                "w-[117px] h-9 text-body-reg-13 no-underline font-medium",
                selected &&
                  "focus:outline-none focus:ring-0 bg-white rounded-t-[8px] text-gray-900",
                !selected && "text-white"
              )}
            >
              Notes
            </button>
          )}
        </Tab>
      </TabList>
      <TabPanels className="w-full">
        <TabPanel className="w-full">
          <section className="flex flex-col pl-6 pr-6 pt-6 pb-9 mb-56 shadow-[0px_2px_6px_rgba(0,0,0,0.1)] bg-white">
            <div className="flex flex-wrap justify-between items-center w-full max-md:max-w-full">
              <div className="flex flex-wrap gap-4 items-center self-stretch my-auto min-w-[240px] max-md:max-w-full">
                <FilterButton />
                <PInput
                  placeholder="Search name or username..."
                  iconName="search"
                  showIcon={true}
                  value={searchText}
                  id="filter-text-box"
                  onChange={handleSearch}
                  className="h-10 w-full md:w-80 sm:w-70 lg:w-96"
                />
              </div>
              <AddNoteButton onClick={handleOpenModal} />
            </div>
            <div className="flex flex-wrap mt-6 mb-0 max-md:mb-2.5">
              <NotesTable searchText={searchText} />
            </div>
          </section>
        </TabPanel>
      </TabPanels>
      <Modal
        isOpen={isModalOpen}
        title="Add Note"
        onClose={handleCloseModal}
        content={
          <div className="flex flex-col gap-4 px-8 py-6 w-[500px]">
            <p className="text-gray-600 font-normal text-body-reg-14">
              Record player issues, requests, and activities through simple
              notes that can be visible to people on your team.
            </p>
            <textarea
              className="h-[166px] min-h-[166px] max-h-[166px] resize-none w-full border border-gray-400 text-gray-500 rounded-md p-4 text-body-reg-13 font-medium"
              placeholder="Add your note..."
            />
          </div>
        }
        footer={
          <>
            <button
              type="button"
              className="bg-gray-200 text-gray-900 px-4 py-2 rounded-lg hover:bg-gray-300 text-body-reg-13 font-semibold w-[140px] h-[40px]"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
            <button className="bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600 w-[140px] h-[40px] font-semibold text-body-reg-13 ml-3">
              Save
            </button>
          </>
        }
      />
    </TabGroup>
  );
};

export default NotesComponent;
